import React, {Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useEffect, useMemo, useRef, useState} from "react";
import {useChatPersonaContext} from "./chat-persona-context";
import {ChatConversationMode, useChatConversationModeContext} from "./chat-conversation-mode";
import {useChatConversationContext} from "./chat-conversation-context";
import {useBlocker, useSearchParams} from "react-router-dom";
import {ChatTaskType, useChatStateContext} from "./chat-state-context";

export enum ChatTool {
  AGENT = "AGENT",
  SOCIAL = "SOCIAL",
  IMAGE = "IMAGE",
  WEB = "WEB",
}

export enum ChatSocialTool {
  TIKTOK = "SOCIAL_TIKTOK",
  REDDIT = "SOCIAL_REDDIT",
  LINKEDIN = "SOCIAL_LINKEDIN",
  YOUTUBE = "SOCIAL_YOUTUBE",
  X_TWITTER = "SOCIAL_X_TWITTER",
  INSTAGRAM = "SOCIAL_INSTAGRAM",
}

export enum ChatImageTool {
  ALL = "IMAGE_ALL",
  STABLE_DIFFUSION = "IMAGE_STABLE_DIFFUSION",
  DALLE = "IMAGE_DALLE",
  IMAGEN = "IMAGE_IMAGEN",
}

export enum ChatWebTool {
  SCRAPE = "WEB_SCRAPE",
  SCRAPE_ADVANCED = "WEB_SCRAPE_ADVANCED",
  WEB = "WEB_WEB",
}

export type ChatToolValue = ChatTool.AGENT | ChatSocialTool | ChatImageTool | ChatWebTool;

export interface ChatToolsContextValue {
  tool: ChatToolValue | null;
  socialTool: ChatSocialTool;
  imageTool: ChatImageTool;
  webTool: ChatWebTool;
  setTool: Dispatch<SetStateAction<ChatTool | null>>;
  setSocialTool: Dispatch<SetStateAction<ChatSocialTool>>;
  setImageTool: Dispatch<SetStateAction<ChatImageTool>>;
  setWebTool: Dispatch<SetStateAction<ChatWebTool>>;
}

export const ChatToolsContext =
  createContext<ChatToolsContextValue | undefined>(undefined);

export const ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP: Record<ChatConversationMode, ChatToolValue[]> = {
  [ChatConversationMode.CONVERSATION]: [
    ChatTool.AGENT,
    Object.values(ChatSocialTool),
    Object.values(ChatImageTool),
    Object.values(ChatWebTool)
  ].flat() as ChatToolValue[],
  [ChatConversationMode.REASONING]: [ChatTool.AGENT],
}

export const ChatToolsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {activePersona: agent} = useChatPersonaContext();
  const {
    [ChatTaskType.IS_SENDING_QUESTION]: {
      state: isSendingQuestion,
    }
  } = useChatStateContext();
  const [searchParams] = useSearchParams();
  const {conversation} = useChatConversationContext();
  const {activePersona} = useChatPersonaContext();
  const {mode: conversationMode} = useChatConversationModeContext();
  const [selectedTool, setSelectedTool] = useState<ChatTool | null>(null);
  const [currentSocialTool, setCurrentSocialTool] = useState<ChatSocialTool>(ChatSocialTool.TIKTOK);
  const [currentImageTool, setCurrentImageTool] = useState<ChatImageTool>(ChatImageTool.ALL);
  const [currentWebTool, setCurrentWebTool] = useState<ChatWebTool>(ChatWebTool.WEB);

  const toolToRestore = useRef<ChatTool | null>(null);

  // If we start to send question, we should remember the tool to restore it after
  useEffect(() => {
    if (isSendingQuestion) {
      toolToRestore.current = selectedTool;
    }
  }, [isSendingQuestion]);

  useEffect(() => {
    if (toolToRestore.current && toolToRestore.current !== selectedTool) {
      setSelectedTool(toolToRestore.current);
    }
  }, [selectedTool]);

  // If we stopped sending question, we should restore the tool
  useEffect(() => {
    if (!isSendingQuestion && toolToRestore.current) {
      setSelectedTool(toolToRestore.current);
      toolToRestore.current = null;
    }
  }, [isSendingQuestion]);

  const getCanvasIdFromSearch = (search: string) => {
    const matches = search.match(/canvasId=([^&]*)/);
    return matches ? matches[1] : null;
  }

  useBlocker((trx) => {
    const oldCanvasId = getCanvasIdFromSearch(trx.currentLocation.search);
    const newCanvasId = getCanvasIdFromSearch(trx.nextLocation.search);

		if (oldCanvasId && oldCanvasId !== newCanvasId && toolToRestore.current) {
      toolToRestore.current = null;
    }

		return false;
	});

  useEffect(() => {
    if (selectedTool === null && activePersona) {
      setSelectedTool(ChatTool.AGENT);
    }
  }, [selectedTool, activePersona])

  useEffect(() => {
    const personaId = searchParams.get("personaId");
    const persona = conversation?.aiPersona;
    if (personaId || persona) {
      setSelectedTool(ChatTool.AGENT);
    } else {
      setSelectedTool(null);
    }
    setCurrentSocialTool(ChatSocialTool.TIKTOK);
    setCurrentImageTool(ChatImageTool.ALL);
    setCurrentWebTool(ChatWebTool.WEB);
  }, [conversation?.id]);

  const activeTool = useMemo(() => {
    if (
      !selectedTool ||
      selectedTool === ChatTool.AGENT && !agent
    ) {
      return null;
    }

    const getTool = (): ChatToolValue => {
      switch (selectedTool) {
        case ChatTool.SOCIAL:
          return currentSocialTool;
        case ChatTool.IMAGE:
          return currentImageTool;
        case ChatTool.WEB:
          return currentWebTool;
        default:
          return selectedTool;
      };
    }

    const result = getTool();

    if (ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP[conversationMode].includes(result)) {
      return result;
    }

    return null;
   }, [agent, selectedTool, currentSocialTool, currentImageTool, currentWebTool, conversationMode]);

   useEffect(() => {
    if (!agent && activeTool === ChatTool.AGENT) {
      setSelectedTool(null);
    }
   }, [agent]);

  return (
    <ChatToolsContext.Provider value={{
      tool: activeTool,
      socialTool: currentSocialTool,
      imageTool: currentImageTool,
      webTool: currentWebTool,
      setTool: setSelectedTool,
      setSocialTool: setCurrentSocialTool,
      setImageTool: setCurrentImageTool,
      setWebTool: setCurrentWebTool,
    }}>
      {children}
    </ChatToolsContext.Provider>
  );
};

export const useChatToolsContext = (): ChatToolsContextValue => {
  const context = React.useContext(ChatToolsContext);

  if (context === undefined) {
    throw new Error(
      "useChatToolsContext must be used within a ChatToolsContextProvider",
    );
  }

  return context;
};
