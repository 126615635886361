import classNames from "classnames/bind";
import React, {ReactElement, useMemo, useState} from "react";

import {AiOrchestrationHistory} from "../../../models/ai-orchestration";
import {Body, Button, ButtonIcon, Spinner, Tooltip} from "../../../shared/v2";
import {ApolloError, useMutation} from "@apollo/client";
import {REGENERATE_REPORT} from "../../../graphql/mutations/ai-orchestration-mutations";
import {Persona} from "../../../models";
import {PersonasList} from "../personas-list";
import {useWorkflowContext, useWorkflowEventsContext} from "../../../context/workflow-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useToastContext} from "../../../context/toast-context";
import {ArrowLeftIcon, ArrowRightIcon, EyePreviewIcon, FileTextIcon} from "../../../icons";
import {AssistiveChip} from "../../../shared/v2/assistive-chip";

import styles from "./history-drawer.module.scss";

interface RegenerateReportData {
  regenerateAiOrchestrationReport: AiOrchestrationHistory;
}

const bStyles = classNames.bind(styles);

export const HistoryDrawer = (): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const [isExpanded, setIsExpanded] = useState(true);
  const {aiHistory, setCurrentHistory, currentHistory, isHistoryLoading} = useWorkflowContext();
  const {isGeneratingReport} = useWorkflowEventsContext();
  const {updateToast} = useToastContext();

  const [regenerateReport] = useMutation<RegenerateReportData>(REGENERATE_REPORT, {
    onCompleted: (data: RegenerateReportData) => {
      if (data.regenerateAiOrchestrationReport) {
        setCurrentHistory(data.regenerateAiOrchestrationReport);
      }
    },
  });

  const handleRegenerateReport = (historyId: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    regenerateReport({ variables: { historyId } });
  };

  const getRegenerateButtonText = (history: AiOrchestrationHistory) => {
    const isLoading = (isGeneratingReport && currentHistory?.id === history.id) ||
      history.processState?.includes('Generating') ||
      history.processState?.includes('regenerat');

    if (isLoading) {
      return (
        <div className={styles.regeneratingWrapper}>
          <Spinner className={styles.regeneratingSpinner} />
          <span>Regenerating...</span>
        </div>
      );
    }
    return "Rerun Reports";
  };

  const formatDate = (entry: AiOrchestrationHistory): string => {
    const date = new Date(entry.completedAt);
    return date.toLocaleString().split(",")[0];
  }

  const getFileName = (url?: string) => {
    if (!url) return "";
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const renderCollapsedContent = () => {
    if (isHistoryLoading) {
      return;
    }

    return aiHistory.map((history, index) => {
      return <div
        className={bStyles("entry", {active: currentHistory?.id === history.id})}
        key={history.id}
        onClick={() => setCurrentHistory(history)}
      >
        <Body
          size="base"
          type="medium"
        >
          {aiHistory.length - index}
        </Body>
    </div>
    })
  }

  const renderContent = () => {
    if (isHistoryLoading) {
      return (
        <div className={styles.loading}>
          <Spinner className={styles.spinner}/>
        </div>
      );
    }

    return aiHistory.map((history, index) => (
      <div
        className={bStyles("entry", {active: currentHistory?.id === history.id})}
        key={history.id}
        onClick={() => setCurrentHistory(history)}
      >
        <div className={styles.firstRow}>
          <div className={styles.textWrapper}>
            <Body
              size="xs"
              type="medium"
              color="text-tertiary"
              className={styles.date}
            >
              {formatDate(history)}
            </Body>
            <div className={styles.runWrapper}>
              <Body
                size="base"
                type="medium"
              >
                Run {aiHistory.length - index}
              </Body>
              {!history?.reportUrl && !history?.reportWordUrl && (
                <AssistiveChip text={history.status === 'failed' ? 'Failed' : 'In progress'} />
              )}
            </div>
          </div>

          <PersonasList
            className={styles.personasList}
            size="sm"
            personas={
              history
                .outputHistoryTasks.map(task => task.persona)
                .filter(persona => Boolean(persona)) as Persona[]
            }
            max={4}
          />
        </div>
        <div className={styles.secondRow}>
          <div className={styles.buttonGroup}>
            {history?.reportUrl && (
              <Button
                leftIcon={<EyePreviewIcon />}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(history?.reportUrl, "_blank");
                }}
                size="small"
              >
                View PDF
              </Button>
            )}
            {history?.reportWordUrl && (
              <Button
                leftIcon={<FileTextIcon />}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(history?.reportWordUrl, "_blank");
                }}
                size="small"
              >
                Open in Word
              </Button>
            )}
            {(history?.reportUrl || history?.reportWordUrl || history.status === 'failed') && (
              <Tooltip content="Generate a new report using existing agent outputs. Will overwrite the existing report. Can take around 2-3 minutes.">
                <Button
                  variant="outlined"
                  onClick={(e) => handleRegenerateReport(history.id, e)}
                  size="small"
                  disabled={isGeneratingReport ||
                    history.processState?.includes('Generating') ||
                    history.processState?.includes('regenerating')}
                  className={bStyles("regenerateButton", {
                    loading: isGeneratingReport && currentHistory?.id === history.id
                  })}
                >
                  {getRegenerateButtonText(history)}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div
      className={bStyles("historyDrawer", {isDarkMode, isExpanded, isCollapsed: !isExpanded})}
    >
      <div className={styles.headerWrapper}>
        {isExpanded &&
        <>
          <Body
            className={styles.header}
            size="s"
            type="medium"
          >
            Workflow history
          </Body>

          <ButtonIcon
            onClick={() => setIsExpanded(!isExpanded)}
            icon={<ArrowLeftIcon />}
            />
          </>
        }
        {!isExpanded &&
          <ButtonIcon
            onClick={() => setIsExpanded(!isExpanded)}
            icon={<ArrowRightIcon />}
          />
        }
      </div>

      {isExpanded &&
        <div className={styles.entriesList}>
          {renderContent()}
        </div>
      }
      {!isExpanded &&
        <div className={styles.entriesList}>
          {renderCollapsedContent()}
        </div>
      }
    </div>
  );
}
