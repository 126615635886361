import {ChatTaskType, useChatStateContext} from "@/context/chat-contexts";
import {useCallback} from "react";

export const useChatTask = <T, RunProps extends Array<unknown>>(
  stateType: ChatTaskType,
	task: (...args: RunProps) => Promise<T>,
	deps?: any[]
) => {
  const {
    [stateType]: {
      state: loading,
      stateRef: loadingRef,
      setState: setLoading,
    },
  } = useChatStateContext();

	const handleSetLoading = (value: boolean) => {
		setLoading(value);
		loadingRef.current = value;
	}

	const run = async (...args: RunProps) => {
		handleSetLoading(true);
		try {
			return await task(...args);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
		} catch (e) {
			handleSetLoading(false);
		} finally {
			handleSetLoading(false);
		}
	};

	const callback = useCallback(run, deps || []);

	return {loading, loadingRef, run: deps ? callback : run};
}
