import React, {ReactElement, useMemo} from "react";

import {GroundingBase} from "./grounding-base";
import {
	TrainingSetFileGrounding,
	TrainingSetVideoGrounding
} from "../../../../../models/ai-model";

export interface DatasetGroundingProps {
  position: number;
  entry: TrainingSetFileGrounding | TrainingSetVideoGrounding
	tooltipInside?: boolean;
	citationMode?: boolean;
}

const isTrainingSetFileGrounding = (entry: DatasetGroundingProps["entry"]): entry is TrainingSetFileGrounding => {
	return entry.type === "trainingSetFile";
}

const DatasetGrounding = ({entry, position, tooltipInside, citationMode}: DatasetGroundingProps): ReactElement => {
	const {
		trainingSetId,
		originalFilename,
		fileUrl,
		trainingSetName,
		trainingSetDescription,
	} = isTrainingSetFileGrounding(entry) ? entry.trainingSetFile : entry.trainingSetVideo;


	const links = useMemo(() => {
		const datasetLinks = [
			{
				to: `/datasets/dataset/${trainingSetId}`,
				text: trainingSetName,
				...(tooltipInside && { tooltip: trainingSetDescription }),
			},
			{to: fileUrl, text: originalFilename}
		];

		const entryPage = entry.page;

		if (entryPage) {
			datasetLinks.push({to: `${fileUrl}#page=${entryPage}`, text: `Page ${entryPage}`});
		}

		return datasetLinks;


	}, [trainingSetName, trainingSetDescription, fileUrl, originalFilename, trainingSetId, entry.page])

	return (
		<GroundingBase
			links={links}
			position={position}
			label="Dataset"
			tooltipInside={tooltipInside}
			citationMode={citationMode}
		/>
	);
}

export default DatasetGrounding;
