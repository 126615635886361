import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {BaseModalProps, Button, Modal, SearchInput, Separator, Spinner} from "../../../shared/v2";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {Campaign, TrainingSet} from "../../../models/ai-model";
import {CampaignCard} from "../../../canvas/select-campaign-modal/campaign-card";
import {CopyDocumentsIcon, HornMegaphoneCampaignIcon} from "../../../icons";
import {GET_CAMPAIGNS, GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {TrainingCardSelect} from "@/canvas/select-chat-sources-modal/components/training-card-select";

import styles from "./select-sources-modal.module.scss";

export interface SelectSourcesModalProps extends BaseModalProps {
  currentSourceIds?: string[];
  onSave?: (surveyIds: string[], trainingSetIds: string[]) => void;
  onSelect?: (surveys: Campaign[], trainingSets: TrainingSet[]) => void;
}

export const SelectSourcesModal = ({isOpen, onClose, currentSourceIds, onSave, onSelect,}: SelectSourcesModalProps) :ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [selectedIds, setSelectedIds] = useState<string[]>(currentSourceIds || []);
	const [search, setSearch] = useState("");

	const [view, setView] = useState<"campaigns" | "datasets">("campaigns");

	const {
		data,
		loading: isLoadingCampaigns,
	} = useQuery<{
		campaigns: {
			items: Campaign[];
			cursor: string;
			remaining: number;
		};
	}>(GET_CAMPAIGNS, {
		variables: {
			workspaceId,
		},
		fetchPolicy: "cache-first",
	});

	const {
		data: { trainingSetsPage: { items: trainingSets = [] } = {} } = {},
		loading: isLoadingDatasets,
	} = useQuery<{
		trainingSetsPage: {
			items: TrainingSet[];
		};
	}>(GET_TRAINING_SETS, {
		variables: {
			workspaceId,
		},
		fetchPolicy: "cache-first",
	});

	useEffect(() => {
		if (currentSourceIds) {
			setSelectedIds(currentSourceIds);
		}
	}, [currentSourceIds?.length]);

	const filteredCampaigns = useMemo(() => {
		if (!search) {
			return data?.campaigns?.items
		}

		return data?.campaigns?.items.filter(
			({name}) => (
				name.toLowerCase().includes(search.toLowerCase())
			)
		)

	}, [search, data?.campaigns?.items]);

	const filteredDatasets = useMemo(() => {
		if (!search) {
			return trainingSets
		}

		return trainingSets.filter(
			({alias}) => (
				alias.toLowerCase().includes(search.toLowerCase())
			)
		)
	}, [search, trainingSets]);


	const handleSelectSource = (source: Campaign | TrainingSet) => {
		if (selectedIds.includes(source.id)) {
			setSelectedIds(selectedIds.filter(item => item !== source.id));
		} else {
			setSelectedIds([...selectedIds, source.id]);
		}
	}

	const renderButtonText = () => {
		if (selectedIds.length === 0) {
			return "Add sources"
		}

		if (selectedIds.length === 1) {
			return "Add 1 source"
		}

		return `Add ${selectedIds.length} sources`
	}

	const getSelectedCampaigns = (selectedCampaignIds: string[]): Campaign[] => {
		if (!data?.campaigns?.items) {
			return [];
		}
		return data.campaigns.items.filter(c => selectedCampaignIds.includes(c.id));
	}

	const getSelectedTrainingSets = (selectedTrainingSetIds: string[]): TrainingSet[] => {
		if (!trainingSets) {
			return [];
		}
		return trainingSets.filter(t => selectedTrainingSetIds.includes(t.id));
	}

	const handleSelectSources = () => {
		const selectedCampaignIds = selectedIds.filter(id => data?.campaigns?.items?.map(c => c.id).includes(id))
		const selectedTrainingSetIds = selectedIds.filter(id => trainingSets.map(t => t.id).includes(id));
		onSave?.(selectedCampaignIds, selectedTrainingSetIds);

		onSelect?.(getSelectedCampaigns(selectedCampaignIds), getSelectedTrainingSets(selectedTrainingSetIds));
		onClose();
	}

	const renderView = () => {
		if (view === "campaigns") {
			if (isLoadingCampaigns) {
				return <Spinner className={styles.spinner} />
			}

			return <div className={styles.items}>
				{filteredCampaigns?.map(campaign => (
					<CampaignCard
						key={campaign.id}
						campaign={campaign}
						isSelected={selectedIds.includes(campaign.id)}
						onSelect={handleSelectSource}
					/>
				))}
			</div>
		}

		if (isLoadingDatasets) {
			return <Spinner className={styles.spinner} />
		}

		return <div className={styles.items}>
			{filteredDatasets?.map((trainingSet) => (
				<TrainingCardSelect
					key={trainingSet.id}
					trainingSet={trainingSet}
					isSelected={selectedIds.includes(trainingSet.id)}
					onSelected={handleSelectSource}
				/>
			))}
		</div>
	}

	return <Modal
		isOpen={isOpen}
		onClose={onClose}
		title="Sources"
		size="large"
		className={styles.modal}
		headerClassName={styles.header}
	>
		<div className={styles.searchSection}>
			<ButtonGroup>
				<ButtonGroupElement
					icon={<HornMegaphoneCampaignIcon />}
					text="Campaigns"
					onClick={() => setView("campaigns")}
					active={view === "campaigns"}
				/>

				<ButtonGroupElement
					icon={<CopyDocumentsIcon />}
					text="Datasets"
					onClick={() => setView("datasets")}
					active={view === "datasets"}
				/>
			</ButtonGroup>

			<SearchInput
				value={search}
				onChange={setSearch}
			/>
		</div>

		<Separator color="workflow" />

		{renderView()}

		<div className={styles.bottomWrapper}>
			<Separator color="workflow" />

			<div className={styles.bottomSection}>
				<Button variant="text" onClick={() => setSelectedIds([])}>
          Clear All
				</Button>

				<div className={styles.actions}>
					<Button className={styles.actionButton} variant="outlined" onClick={onClose}>Cancel</Button>
					<Button
						className={styles.actionButton}
						onClick={handleSelectSources}
					>
						{renderButtonText()}
					</Button>
				</div>
			</div>
		</div>
	</Modal>
}
