import React, {ReactElement} from "react"
import {Outlet} from "react-router";

import {TopBar} from "../../components/topbar";
import {WorkflowProvider, WorkflowEventsProvider, CarouselScrollProvider, WorkflowModalsProvider, CarouselConstraintsContextProvider} from "../../../context/workflow-contexts";
import {RunWorkflowContextProvider} from "../../../context/workflow-contexts/run-workflow-context";
import {useMount} from "../../../hooks/useMount";
import {ImageStudio} from "../../../canvas/image-studio";
import {ImageHistoryContextProvider} from "../../../canvas/image-studio/contexts";
import {
	ChatMentionsContextProvider,
	ChatSendQuestionContextProvider,
	ChatScrollContextProvider,
	ChatMessagesContextProvider,
	ChatImageContextProvider,
	ChatFollowUpsContextProvider,
	ChatPersonaContextProvider,
	ChatConversationContextProvider,
	ChatConversationModeContextProvider,
	ChatToolsContextProvider
} from "../../../context/chat-contexts";
import {ChatStateContextProvider} from "@/context/chat-contexts/chat-state-context";

import styles from "./flow-page.module.scss"

export const FlowPage = (): ReactElement => {
	useMount(() => {document.title = "Vurvey - Workflow"})

	return (
		<ChatStateContextProvider>
			<ImageHistoryContextProvider>
				<ChatConversationContextProvider>
					<ChatConversationModeContextProvider>
						<ChatPersonaContextProvider>
							<ChatToolsContextProvider>
								<ChatMessagesContextProvider>
									<ChatImageContextProvider>
										<ChatFollowUpsContextProvider>
											<ChatMentionsContextProvider>
												<ChatSendQuestionContextProvider>
													<ChatScrollContextProvider>
														<CarouselConstraintsContextProvider>
															<CarouselScrollProvider>
																<WorkflowProvider>
																	<RunWorkflowContextProvider>
																		<WorkflowEventsProvider>
																			<WorkflowModalsProvider>
																				<div className={styles.layout}>
																					<TopBar />
																					<Outlet  />
																					<ImageStudio />
																				</div>
																			</WorkflowModalsProvider>
																		</WorkflowEventsProvider>
																	</RunWorkflowContextProvider>
																</WorkflowProvider>
															</CarouselScrollProvider>
														</CarouselConstraintsContextProvider>
													</ChatScrollContextProvider>
												</ChatSendQuestionContextProvider>
											</ChatMentionsContextProvider>
										</ChatFollowUpsContextProvider>
									</ChatImageContextProvider>
								</ChatMessagesContextProvider>
							</ChatToolsContextProvider>
						</ChatPersonaContextProvider>
					</ChatConversationModeContextProvider>
				</ChatConversationContextProvider>
			</ImageHistoryContextProvider>
		</ChatStateContextProvider>
	);
}
