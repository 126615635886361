import React, {HTMLProps} from "react";
import {Link} from "@/hoc/layout/navigation/link";

import {useChatScrollContext} from "../../../../../context/chat-contexts/chat-scroll-context";
import {useImageHistoryContext} from "../../../../image-studio/contexts";

import styles from "./link-reader.module.scss";

const getServiceInfo = (url: string) => {
	if (url.includes("/sd/")) return {name: "Stable Diffusion", color: "#a69cfe"};
	if (url.includes("/dalle/")) return {name: "Dalle", color: "#D0F8AB"};
	if (url.includes("/google/")) return {name: "Imagen", color: "#A5F0FC"};
	return {name: "User Upload", color: "#a69cfe"};
};

const LinkRenderer = ({href, children}: HTMLProps<HTMLAnchorElement>) => {
	const {scrollListToBottom} = useChatScrollContext();
  const {setImageSrc} = useImageHistoryContext();
	const {name, color} = getServiceInfo(href ?? "");

	const isImage = (content: string) => {
		const imageUrlRegex = /(https:\/\/\S+\.(?:png|jpg|jpeg|gif|webp))/i;
		return imageUrlRegex.test(content);
	};

	const handleClick = (href: string | undefined) => {
		if (href) {
			scrollListToBottom();
			setImageSrc(href);
		}
	};

	if (isImage(href ?? "")) {
		return (
			<div className={styles.imageContainer}>
				<div
					className={styles.imageLabelContainer}
					style={{backgroundColor: color}}
				>
					{name}
				</div>
				<img
					className={styles.amazonImg}
					src={href}
					onClick={() => handleClick(href)}
					alt={children?.[0] as string}
					loading="lazy"
				/>
			</div>
		)
	}

	return (
		//@ts-ignore
		<Link to={href} target="_blank" rel="noreferrer">{children}</Link>
	);
};

export default LinkRenderer;
