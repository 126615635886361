import classNames from "classnames/bind";
import React, {ReactElement, ReactNode, useEffect, useMemo, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";

import {Avatar, Body} from "../../../shared/v2";
import {ChatImageTool, ChatSocialTool, ChatTool, ChatToolValue, ChatWebTool, useChatConversationContext, useChatPersonaContext, useChatSendQuestionContext, useChatToolsContext} from "../../../context/chat-contexts";
import {CloseIconWrapper} from "../close-icon-wrapper";
import {GeminiIcon, ImagePictureIcon, OpenAiIcon, SocialInstagramIcon, SocialLinkedinIcon, SocialRedditIcon, SocialTiktokIcon, SocialXTwitterIcon, SocialYoutubeIcon, StableDiffusionIcon, VurveyYellowLogoIcon, WebGlobeIcon, WebGlobeMouseCoursorIcon, WebGlobePlusAdvancedIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./persona-tab-wrapper.module.scss";

const cx = classNames.bind(styles);

enum BookmarkType {
	PERSONA = "persona",
	TOOL = "tool",
}

type BookmarkParams = {
	type: BookmarkType.PERSONA;
} | {
	type: BookmarkType.TOOL;
	label: string;
	className: string;
	icon: ReactElement;
}

export interface PersonaTabWrapperProps {
  children?: ReactNode;
}

const MAP: Record<ChatToolValue, BookmarkParams> = {
	[ChatTool.AGENT]: {type: BookmarkType.PERSONA},
	[ChatSocialTool.LINKEDIN]: {
		type: BookmarkType.TOOL,
		label: "LinkedIn",
		className: styles.socialLinkedin,
		icon: <SocialLinkedinIcon className={styles.icon} />,
	},
	[ChatSocialTool.REDDIT]: {
		type: BookmarkType.TOOL,
		label: "Reddit",
		className: styles.socialReddit,
		icon: <SocialRedditIcon className={styles.icon} />,
	},
	[ChatSocialTool.TIKTOK]: {
		type: BookmarkType.TOOL,
		label: "TikTok",
		className: styles.socialTiktok,
		icon: <SocialTiktokIcon className={styles.icon} />,
	},
	[ChatSocialTool.X_TWITTER]: {
		type: BookmarkType.TOOL,
		label: "X/Twitter",
		className: styles.socialXTwitter,
		icon: <SocialXTwitterIcon className={styles.icon} />,
	},
	[ChatSocialTool.INSTAGRAM]: {
		type: BookmarkType.TOOL,
		label: "Instagram",
		className: styles.socialInstagram,
		icon: <SocialInstagramIcon className={styles.icon} />,
	},
	[ChatSocialTool.YOUTUBE]: {
		type: BookmarkType.TOOL,
		label: "YouTube",
		className: styles.socialYoutube,
		icon: <SocialYoutubeIcon className={styles.icon} />,
	},
	[ChatImageTool.ALL]: {
		type: BookmarkType.TOOL,
		label: "All",
		className: styles.image,
		icon: <ImagePictureIcon className={styles.icon} />,
	},
	[ChatImageTool.STABLE_DIFFUSION]: {
		type: BookmarkType.TOOL,
		label: "Stable Diffusion",
		className: styles.image,
		icon: <StableDiffusionIcon className={styles.iconStableDiffusion} />,
	},
	[ChatImageTool.DALLE]: {
		type: BookmarkType.TOOL,
		label: "DALL-E",
		className: styles.image,
		icon: <OpenAiIcon className={styles.iconOpenAi} />,
	},
	[ChatImageTool.IMAGEN]: {
		type: BookmarkType.TOOL,
		label: "Google Imagen",
		className: styles.image,
		icon: <GeminiIcon className={styles.iconGemini} />,
	},
	[ChatWebTool.SCRAPE]: {
		type: BookmarkType.TOOL,
		label: "Scrape",
		className: styles.web,
		icon: <WebGlobeMouseCoursorIcon className={styles.icon} />,
	},
	[ChatWebTool.SCRAPE_ADVANCED]: {
		type: BookmarkType.TOOL,
		label: "Scrape advanced",
		className: styles.web,
		icon: <WebGlobePlusAdvancedIcon className={styles.icon} />,
	},
	[ChatWebTool.WEB]: {
		type: BookmarkType.TOOL,
		label: "Web",
		className: styles.web,
		icon: <WebGlobeIcon className={styles.icon} />,
	},
}

export const PersonaTabWrapper = ({children}: PersonaTabWrapperProps): ReactElement => {
	const {tool: activeTool, setTool} = useChatToolsContext();
	const {isSendingQuestionRef} = useChatSendQuestionContext();
	const [innerActiveTool, setInnerActiveTool] = useState<ChatToolValue | null>(null);
	const {isDarkMode} = useThemeMode();
	const {activePersona, savePersona} = useChatPersonaContext();
	const {isLoading: isDisabled} = useChatConversationContext();
	const [isClosing, setIsClosing] = useState(false);

	useEffect(() => {
		if (activeTool !== innerActiveTool && innerActiveTool && !isSendingQuestionRef.current) {
			setIsClosing(true);
		} else {
			setInnerActiveTool(activeTool);
		}
	}, [activeTool, innerActiveTool]);

	const currentBookmark = useMemo(() => {
		if (!innerActiveTool) {
			return null;
		}

		const bookmarkParams = MAP[innerActiveTool];

		if (!bookmarkParams) {
			throw new Error(`Unknown tool: ${innerActiveTool}`);
		}

		return bookmarkParams;
	}, [innerActiveTool]);

	const handleClose = () => {
		if (!currentBookmark) {
			throw new Error("Cannot close a non-existing bookmark");
		}

		if (currentBookmark.type === BookmarkType.PERSONA) {
			savePersona();
		} else {
			setTool(null);
		}
	};

	const bookmarkClassName = useMemo(() => {
		if (!currentBookmark) {
			return "";
		}

		if (currentBookmark.type === BookmarkType.PERSONA) {
			return "persona";
		}

		return currentBookmark.className;
	}, [currentBookmark]);

	const bookmark = useMemo(() => {
		if (!currentBookmark || isClosing) {
			return null;
		}

		let content: ReactElement[] = [];

		if (currentBookmark.type === BookmarkType.PERSONA) {
			const {isVurvey, picture, name} = activePersona || {};

			content.push(
				<Avatar
					firstName={name}
					size="xs"
					url={picture?.url}
					additionalIcon={isVurvey ? <VurveyYellowLogoIcon /> : undefined}
					key={picture?.url}
				/>
			);
		} else if (currentBookmark.type === BookmarkType.TOOL) {
			content.push(currentBookmark.icon);
		}

		content.push(
			<Body key={1} className={styles.name} size="s" type="medium">
				{currentBookmark.type === BookmarkType.PERSONA ? activePersona?.name : currentBookmark.label}
			</Body>
		);

		return (
			<motion.div
				initial={{y: "33px"}}
				animate={{y: 0}}
				exit={{y: "33px"}}
				transition={{duration: 0.3}}
			>
				<CloseIconWrapper
					disabled={isDisabled}
					onClose={handleClose}
					className={styles.bookmark}
				>
					{content}
				</CloseIconWrapper>
			</motion.div>
		)
	}, [currentBookmark, isClosing, isDisabled, savePersona]);

	return (
		<div className={cx("bookmarkWrapper", bookmarkClassName, {isDarkMode})}>
			<AnimatePresence onExitComplete={() => {
				setInnerActiveTool(activeTool);
				setIsClosing(false);
			}}>
				{bookmark}
			</AnimatePresence>

			<div className={styles.border}>
				{children}
			</div>
		</div>
	);
}
