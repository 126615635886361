import classNames from "classnames/bind";
import {ButtonHTMLAttributes, ReactElement, ReactNode, createElement} from "react";

import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./button-icon.module.scss";

const cx = classNames.bind(styles);

export interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	icon: ReactNode;
	color?: "text-body" | "text-body-dark" | "text-secondary" | "gray-modern";
  size?: "small" | "default";
	filledIcon?: boolean;
	isActive?: boolean;
	theme?: "light" | "dark";
	disabled?: boolean;
}

const ButtonIcon = ({
	className,
	icon,
	color = "text-body",
	size = "default",
	isActive = false,
	filledIcon,
	theme,
	disabled,
	...props
}: ButtonIconProps): ReactElement => {
	const {isDarkMode} = useThemeMode(theme);

	return createElement(
		props.onClick ? "button" : "div",
		{disabled, className: cx("buttonIcon", className, color, size, {disabled, filledIcon, isDarkMode, active: isActive}), ...props},
		icon,
	);
};

export {ButtonIcon}
