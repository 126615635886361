/* eslint-disable no-useless-escape */

import {IconTypes} from "../components/icon";
import {QuestionSubType, QuestionType} from "../../models/questions";
import {HighlightCategory} from "../../models/transcript";
import {TremendousCurrencyType} from "../../models/rewards";

export const CREATOR_COUNT = "25,084";

export const WORKSPACEROLES = ["ADMINISTRATOR", "MANAGER", "CREATOR"];

export const DEFAULT_QUESTION = {
	type: "VIDEO",
	text: "Untitled Question",
};

export const ATTRIBUTE_TYPES = ["USER", "SURVEY"];

// Could just make it normally. Might be doing clever > clear.
export const CURRENCY_SELECT_VALUES =
Object.values(TremendousCurrencyType).map(v => ({text: v, value: v}));

export interface SurveyLanguage {
	id: string;
	name: string;
}
// Used mostly for the publish modal. As far as I can tell, these are constant.
export const SURVEY_LANGUAGES: SurveyLanguage[] = [
	{
		id: "9843a0f5-495d-4b5c-a875-f286728de894",
		name: "English (US)",
	},
	{
		id: "f62d4b57-120c-4c81-8c12-e9542a47c555",
		name: "Spanish",
	},
	{
		id: "f91a5f2c-fc1c-4e77-b16e-6882e50c693a",
		name: "French",
	},
	{
		id: "2192858a-34dd-462f-9ea3-600e4843aa9a",
		name: "German",
	},
	{
		id: "b06e1287-4099-4469-99dd-bd20df8a8d04",
		name: "Italian",
	},
	{
		id: "d1c83944-11a3-4f25-a17c-a016a72047b1",
		name: "Dutch",
	},
];

interface QuestionDefaults {
	type: QuestionType;
	videoResponse: boolean;
	min?: number;
	max?: number;
	rank: boolean;
	step?: number;
	subtype?: string | null;
}

/**
 * Similar to Defaults, but the variables we will use
 * when changing from one question to another.
 */
interface QuestionChanges {
	type: QuestionType;
	step?: number;
	changes: {
		max?: number;
		min?: number;
		subtype?: string | null;
		videoResponse?: boolean;
		rank?: boolean;
	}
}

type IQuestionChanges = {
	[key in QuestionSubType]: QuestionChanges;
};

type IQuestionSubType = {
	[key in QuestionSubType]: QuestionDefaults
};

export const QUESTION_DEFAULTS: IQuestionSubType = {
	BARCODE: {
		type: QuestionType.BARCODE,
		videoResponse: false,
		rank: false,
		min: 1,
		max: 10,
	},
	CHOICE: {
		type: QuestionType.CHOICE,
		videoResponse: false,
		max: 1,
		rank: false,
	},
	MULTISELECT: {
		type: QuestionType.CHOICE,
		videoResponse: false,
		max: 20,
		rank: false,
	},
	RANKED: {
		type: QuestionType.CHOICE,
		videoResponse: false,
		max: 20,
		rank: true,
	},
	STAR: {
		type: QuestionType.SLIDER,
		videoResponse: false,
		rank: false,
		min: 1,
		max: 5,
		step: 1,
		subtype: "star",
	},
	SHORT: {
		type: QuestionType.TEXT,
		videoResponse: false,
		rank: false,
		subtype: "short",
	},
	LONG: {
		type: QuestionType.TEXT,
		videoResponse: false,
		rank: false,
		subtype: "long",
	},
	NUMBER: {
		type: QuestionType.TEXT,
		videoResponse: false,
		rank: false,
		subtype: "number",
	},
	PICTURE: {
		type: QuestionType.PICTURE,
		videoResponse: false,
		subtype: "picture",
		rank: false,
		min: 1,
		max: 1,
	},
	VIDUPLOAD: {
		type: QuestionType.PICTURE,
		videoResponse: false,
		subtype: "video",
		rank: false,
		min: 1,
		max: 1,
	},
	PDF: {
		type: QuestionType.PICTURE,
		videoResponse: false,
		subtype: "pdf",
		rank: false,
		min: 1,
		max: 1,
	},
	SLIDER: {
		type: QuestionType.SLIDER,
		videoResponse: false,
		rank: false,
		min: 1,
		max: 10,
		step: 1,
	},
	VIDEO: {
		type: QuestionType.NONE,
		videoResponse: true,
		rank: false,
	},
};

export const QUESTION_CHANGES: IQuestionChanges = {
	BARCODE: {
		type: QuestionType.BARCODE,
		changes: {
			max: 10,
			min: 1,
			rank: false,
		},
	},
	CHOICE: {
		type: QuestionType.CHOICE,
		changes: {
			max: 1,
			min: 1,
			rank: false,
		},
	},
	MULTISELECT: {
		type: QuestionType.CHOICE,
		changes: {
			min: 1,
			max: 20,
			rank: false,
		},
	},
	RANKED: {
		type: QuestionType.CHOICE,
		changes: {
			min: 1,
			max: 20,
			rank: true,
		},
	},
	STAR: {
		type: QuestionType.SLIDER,
		changes: {
			min: 1,
			max: 5,
			rank: false,
			subtype: "star",
		},
		step: 1,
	},
	SLIDER: {
		type: QuestionType.SLIDER,
		changes: {
			rank: false,
			min: 1,
			max: 10,
			subtype: null,
		},
		step: 1,
	},
	SHORT: {
		type: QuestionType.TEXT,
		changes: {
			rank: false,
			subtype: "short",
		},
	},
	LONG: {
		type: QuestionType.TEXT,
		changes: {
			rank: false,
			subtype: "long",
		},
	},
	NUMBER: {
		type: QuestionType.TEXT,
		changes: {
			rank: false,
			subtype: "number",
		},
	},
	PICTURE: {
		type: QuestionType.PICTURE,
		changes: {
			subtype: "picture",
			rank: false,
			min: 1,
			max: 1,
		},
	},
	VIDUPLOAD: {
		type: QuestionType.PICTURE,
		changes: {
			subtype: "video",
			videoResponse: false,
			rank: false,
			min: 1,
			max: 1,
		},
	},
	PDF: {
		type: QuestionType.PICTURE,
		changes: {
			subtype: "pdf",
			rank: false,
			min: 1,
			max: 1,
		},
	},
	VIDEO: {
		type: QuestionType.NONE,
		changes: {
			videoResponse: true,
			rank: false,
		},
	},
};

interface QuestionDetails {
	type: QuestionType;
	text: string;
	icon: IconTypes;
	info?: string;
}

type IQuestionDetails = {
	[key in QuestionSubType]: QuestionDetails;
};


export const QUESTION_TYPES: IQuestionDetails = {
	VIDEO: {
		type: QuestionType.NONE,
		text: "Video",
		icon: "question-video",
	},
	CHOICE: {
		type: QuestionType.CHOICE,
		text: "Multiple Choice",
		icon: "question-mc",
	},
	MULTISELECT: {
		type: QuestionType.CHOICE,
		text: "Multiselect",
		icon: "question-multiselect",
	},
	RANKED: {
		type: QuestionType.CHOICE,
		text: "Ranking",
		icon: "question-ranked",
	},
	STAR: {
		type: QuestionType.SLIDER,
		text: "Star Rating",
		icon: "question-star",
	},
	SLIDER: {
		type: QuestionType.SLIDER,
		text: "Opinion Slider",
		icon: "question-slider",
	},
	SHORT: {
		type: QuestionType.TEXT,
		text: "Short Text",
		icon: "question-short",
	},
	LONG: {
		type: QuestionType.TEXT,
		text: "Long Text",
		icon: "question-long",
	},
	NUMBER: {
		type: QuestionType.TEXT,
		text: "Number",
		icon: "question-number",
	},
	PICTURE: {
		type: QuestionType.PICTURE,
		text: "Image Upload",
		icon: "question-image",
		info: "Supports JPEG, PNG, and GIF with max file size of 25 MB",
	},
	PDF: {
		type: QuestionType.PICTURE,
		text: "PDF Upload",
		icon: "questionPdfUpload",
		info: "Supports PDF files only",
	},
	VIDUPLOAD: {
		type: QuestionType.PICTURE,
		text: "Video Upload",
		icon: "questionVideoUpload",
		info: "Supports video formats (MP4, MP3, WEBM)",
	},
	BARCODE: {
		type: QuestionType.BARCODE,
		text: "Scan",
		icon: "qrCode",
		info: "user can scan products to show what they've purchased or own",
	},
};

/**
 * Current search types available. Can add more if needed.
 * May be useful to make something like IQuestionDetails
 */
export const SORT_TYPES = [
	{text: "Most Recent", value: "NEWEST_FIRST"},
	{text: "Oldest First", value: "OLDEST_FIRST"},
	{text: "Most Likes", value: "MOST_LIKES"},
	{text: "Most Highlights", value: "MOST_HIGHLIGHTS"},
	{text: "Shortest First", value: "SHORTEST_FIRST"},
	{text: "Longest First", value: "LONGEST_FIRST"},
	{text: "Overall Score", value: "OVERALL_SCORE"},
	{text: "Audio Quality", value: "AUDIO_QUALITY"},
	{text: "Video Quality", value: "VIDEO_QUALITY"},
	{text: "Interesting Score", value: "INTERESTING_SCORE"},
	{text: "Engagement", value: "ENGAGEMENT"},
];
export const TEXT_SORT_TYPES = [
	{text: "Most Recent", value: "NEWEST_FIRST"},
	{text: "Oldest First", value: "OLDEST_FIRST"},
	{text: "Most Likes", value: "MOST_LIKES"},
	{text: "Alphabetical", value: "TEXT"},
];
export const NUMBER_SORT_TYPES = [
	{text: "Most Recent", value: "NEWEST_FIRST"},
	{text: "Oldest First", value: "OLDEST_FIRST"},
	{text: "Most Likes", value: "MOST_LIKES"},
];

/**
 * Main permissions that would be found on the workspace members page.
 * Has to do with managing members
 */
export const MANAGE_WORKSPACE_PERMISSIONS = [
	"read",
	"update",
	"manageBilling",
	"delete",
	"Member.addCreator",
	"Member.addAdministrator",
	"Member.addOwner",
	"Member.makeCreator",
	"Member.makeAdministrator",
	"Member.remove",
	"Member.makeOwner",
];
export enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
	NONBINARY = "NONBINARY",
}
export enum SurveyStatus {
	ARCHIVED = "ARCHIVED",
	CLOSED = "CLOSED",
	DRAFT = "DRAFT",
	OPEN = "OPEN",
}

export enum SurveyAccessLevel {
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE",
	ANONYMOUS = "ANONYMOUS",
}

export const DragItemTypes = {
	OPTIONCARD: "option",
	QUESTIONCARD: "question",
	CLIP: "clip",
};
export interface TimerConst {
	text: string;
	badge: undefined | "PRO" | "ENTERPRISE";
	seconds: number;
}

export const WorkspaceTimers: TimerConst[] = [
	{text: "30 seconds", badge: undefined, seconds: 30},
	{text: "1 minute", badge: "PRO", seconds: 60},
	{text: "3 minutes", badge: "PRO", seconds: 180},
	{text: "5 minutes", badge: "ENTERPRISE", seconds: 300},
	{text: "10 minutes", badge: "ENTERPRISE", seconds: 600},
];

export const barColors = [
	"#5F4DFF",
	"#BDB6FF",
	"#ECE2CB",
	"#C7B8BD",
	"#B4778B",
	"#2B2A56",
	"#6F90D8",
	"#ABC0F0",
	"#CD9E65",
	"#F3BF74",
];


/**
 * This may require a bit more verifying but from what I can tell, in all
 * environments, these are the current defaults. Doing this so we don't
 * need to query for highlightCategories all the time.
 */
export const HighlightCatDefaults: HighlightCategory[] = [
	{
		id: "82aa8b23-ea92-4012-8fb2-591238d05caa",
		name: "Positive",
		color: "#00FF00",
	},
	{
		id: "b54669d2-e04f-4bfb-9a4e-738a4c27dfcd",
		name: "Negative",
		color: "#FF0000",
	},
	{
		id: "c4fbbf12-7fb6-469e-b40b-ff63f554e56c",
		name: "Add to Reel",
		color: "#FFFF00",
	},
	{
		id: "a560f23d-9f09-4518-815b-4596d61987c3",
		name: "Highlight",
		color: "#FBFF3B",
	},
];

export const COPILOT_CONVERSATIONS_LIMIT = 10;

export const USER_FIELD_MAX_LENGTH = 200;

export const NEW_ROUTES_REGEX_STRING = [
	"^\/$",
	"^\/admin(\/[a-z0-9\\-]*){0,}$",
	"^\/agents(\/[a-z0-9\\-]*){0,}$",
	"^\/audience(\/[a-z0-9\\-]*){0,}$",
	"^\/branding$",
	"^\/datasets(\/[a-z0-9\\-]*){0,}$",
	"^\/magic-reels$",
	"^\/me(\/[a-z0-9\\-]*){0,}$",
	"^\/mentions(\/[a-z0-9\\-]*){0,}$",
	"^\/templates$",
	"^\/usage$",
	"^\/workflow(\/[a-z0-9\\-]*){0,}$",
	"^\/workspace(\/[a-z0-9\\-]*){0,}$",
	"^\/campaigns(\/[a-z0-9\\-]*){0,}$",
].join("|");

export const [DARK_MODE_ONLY_ROUTES] = [
	"test",
	// "^\/workflow/flows(\/[a-z0-9\\-]*){0,}$",
]

export const ROUTES_WITHOUT_NAVIGATION_PANEL = [
	"/survey",
	"/reel",
	"/agents/builder",
	"/workflow/flows/",
];

// Defaults for create agent
export const AGENT_MAX_TOKENS = 1024;


export const CHAT_MESSAGE_FAKE_INPUT_MARKER = "FAKE_INPUT_";
export const CHAT_MESSAGE_LOADING_MARKER = "LOADING_";

export const CHAT_COMMANDS = [
	{command: "/table", description: " (returns response formatted as a table)", icon: "📊"},
	{
		command: "/image",
		description:
			" (Uses a mix of Imagen, Stable Diffusion, and DALL-E to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/imagesd",
		description: " (Uses Stable Diffusion to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/imagede",
		description: " (Uses OpenAI DALL-E to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/imagen",
		description: " (Uses Google Imagen to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/tiktok",
		description:
			"Allows you to pass in a hashtag to search TikTok to gather and understand the insights, sentiment, top vidoes, etc in order to understand what people are saying or posting about a particular topic. Example: /tiktok #slugging",
		icon: "📈",
	},
	{
		command: "/scrape",
		description:
			"Will scrape the URL (/scrape [url]) that you provide and return a summary of the content while adding the full content to the Scraped-Data Dataset folder.",
		icon: "🌐",
	},
	{
		command: "/scrape_advanced",
		description:
			"Similar to /scrape [url] but will sometimes provide more detail on complicated websites where the default /scrape under performs. This can take much longer though and should only be used when /scrape fails.",
		icon: "🌐",
	},
	{
		command: "/web",
		description:
			"Will use the web to find the answer to your question with supporting images and links if appropriate. Example: /web Who is current president of the USA?",
		icon: "🌐",
	},
	{command: "/help", description: " (shows help)", icon: "❓"},
];
