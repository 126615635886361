import React, {createContext, useContext, useEffect, useState} from "react";
import {useSubscription, useQuery} from "@apollo/client";
import {ORCHESTRATION_EVENTS} from "../../graphql/subscriptions";
import {GET_AI_ORCHESTRATION_HISTORY} from "../../graphql/queries/ai-orchestration-queries";
import {AiOrchestrationHistoryData} from "../../models/ai-orchestration";
import {
    VurveyOrchestrationEvent,
    TaskCompleted,
    TaskOutputGenerated,
    TaskStarted,
    TaskError,
    TaskUpdated,
    OrchestrationCompleted,
    ReportGeneratingCompleted,
    WorkflowCreationStarted,
    AgentCreationProgress,
    WorkflowCreationCompleted,
    WorkflowCreationError,
} from "../../models/workflow-events";
import {useToastContext} from "../toast-context";
import {useParams} from "react-router";
import {useWorkflowContext} from "./workflow-context";
import {useCarouselScrollContext} from "./carousel-scroll-context";

interface WorkflowEventsContextProps {
    isGeneratingReport: boolean;
    workflowCreationStarted?: {
        orchestrationId: string;
        name: string;
    };
    agentCreationProgress?: {
        orchestrationId: string;
        agentName: string;
        progress: number;
        total: number;
    };
    workflowCreationCompleted?: {
        orchestrationId: string;
        agentCount: number;
    };
    workflowCreationError?: {
        orchestrationId: string;
        error: string;
    };
    setActiveOrchestrationId: (id: string | undefined) => void;
}

const WorkflowEventsContext = createContext<WorkflowEventsContextProps | undefined>(undefined);

export const WorkflowEventsProvider = ({children}: { children: React.ReactNode }) => {
    const {updateToast} = useToastContext();
    const {workflowId} = useParams();
    const [activeOrchestrationId, setActiveOrchestrationId] = useState<string | undefined>(undefined);
    
    // Only use workflow context if we have a workflowId
    const workflowContext = workflowId ? useWorkflowContext() : null;
    const carouselContext = workflowId ? useCarouselScrollContext() : null;

    // Get current workflow status
    const {data: workflowHistoryData} = useQuery<AiOrchestrationHistoryData>(GET_AI_ORCHESTRATION_HISTORY, {
        variables: {
            orchestrationId: workflowId
        },
        skip: !workflowId,
        fetchPolicy: "network-only",
    });

    // Only set active orchestration ID if workflow is actually running
    useEffect(() => {
        const currentRun = workflowHistoryData?.aiOrchestrationHistory?.find(
            (history) => (!history.completedAt && history.status === 'in_progress') || 
                        (history.status === 'completed' && !history.reportUrl) // Keep subscription active if report is still generating
        );
        
        setActiveOrchestrationId(currentRun ? workflowId : undefined);
    }, [workflowId, workflowHistoryData]);

    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [workflowCreationStarted, setWorkflowCreationStarted] = useState<WorkflowEventsContextProps['workflowCreationStarted']>();
    const [agentCreationProgress, setAgentCreationProgress] = useState<WorkflowEventsContextProps['agentCreationProgress']>();
    const [workflowCreationCompleted, setWorkflowCreationCompleted] = useState<WorkflowEventsContextProps['workflowCreationCompleted']>();
    const [workflowCreationError, setWorkflowCreationError] = useState<WorkflowEventsContextProps['workflowCreationError']>();

    interface OrchestrationSubscriptionData {
        orchestrationUpdates: VurveyOrchestrationEvent;
    }

    // Set up subscription with retry logic
    const {error: subscriptionError} = useSubscription<OrchestrationSubscriptionData>(ORCHESTRATION_EVENTS, {
        variables: {orchestrationId: activeOrchestrationId},
        skip: !activeOrchestrationId,
        shouldResubscribe: true,
        onSubscriptionComplete: () => {
            console.log('Subscription completed for:', activeOrchestrationId);
            // Only clear states if we're not in an error state
            if (!workflowCreationError) {
                setWorkflowCreationStarted(undefined);
                setAgentCreationProgress(undefined);
                setWorkflowCreationCompleted(undefined);
            }
        },
        onSubscriptionData: ({subscriptionData}) => {
            console.log('Subscription data received:', {
                activeId: activeOrchestrationId,
                data: subscriptionData?.data?.orchestrationUpdates,
                timestamp: new Date().toISOString()
            });
            
            const event = subscriptionData?.data?.orchestrationUpdates;
            if (event) {
                // Don't process any more events if we've already completed
                if (workflowCreationCompleted) {
                    console.log('Skipping event - workflow already completed:', {
                        type: event.type,
                        timestamp: new Date().toISOString()
                    });
                    return;
                }

                // Process event
                console.log('Processing event:', {
                    type: event.type,
                    orchestrationId: event.data.orchestrationId,
                    data: event.data,
                    timestamp: new Date().toISOString()
                });
                handleWorkflowEvent(event);

                // If this was the completion event, stop subscription
                if (event.type === 'WorkflowCreationCompleted') {
                    console.log('Workflow completed, stopping subscription');
                    setActiveOrchestrationId(undefined);
                }
            }
        },
        onError: (error) => {
            console.error('Subscription error:', {
                error,
                orchestrationId: activeOrchestrationId,
                timestamp: new Date().toISOString()
            });

            // Handle different types of errors
            if (error.message.includes('Failed to fetch') || error.message.includes('Socket closed')) {
                // Network/connection error - let Apollo Client handle retry
                console.log('Network error detected, Apollo Client will handle retry');
                
                // Show a more user-friendly message
                updateToast({
                    description: "Connection interrupted. Attempting to reconnect...",
                    type: "informational",
                });
            } else {
                // Other errors - show error and reset state
                updateToast({
                    description: `WebSocket error: ${error.message}`,
                    type: "failure",
                });
                
                // Set error state but keep orchestrationId for retry
                setWorkflowCreationError({
                    orchestrationId: activeOrchestrationId || '',
                    error: error.message
                });
            }
        }
    });

    // Handle subscription setup and cleanup
    useEffect(() => {
        if (activeOrchestrationId) {
            console.log('Setting up subscription for running workflow:', {
                id: activeOrchestrationId,
                timestamp: new Date().toISOString()
            });
            
            // Return cleanup function
            return () => {
                console.log('Cleaning up subscription for workflow:', {
                    id: activeOrchestrationId,
                    timestamp: new Date().toISOString()
                });
                // Clear all workflow states on cleanup
                setWorkflowCreationStarted(undefined);
                setAgentCreationProgress(undefined);
                setWorkflowCreationCompleted(undefined);
                setWorkflowCreationError(undefined);
                if (workflowContext) {
                    workflowContext.setRunInProgress(false);
                }
            };
        }
    }, [activeOrchestrationId, workflowContext]);

    // Handle subscription errors with retry logic
    useEffect(() => {
        if (subscriptionError) {
            console.error('Subscription error occurred:', subscriptionError);
            
            // For network errors, keep the orchestrationId active for retry
            if (subscriptionError.message.includes('Failed to fetch') || 
                subscriptionError.message.includes('Socket closed')) {
                // Let Apollo Client handle retry with its built-in mechanisms
                return;
            }
            
            // For other errors, reset state after a delay to allow for cleanup
            const timeoutId = setTimeout(() => {
                setActiveOrchestrationId(undefined);
                setWorkflowCreationStarted(undefined);
                setAgentCreationProgress(undefined);
                setWorkflowCreationCompleted(undefined);
                setWorkflowCreationError(undefined);
            }, 1000);
            
            return () => clearTimeout(timeoutId);
        }
    }, [subscriptionError]);

    // Handle workflow creation events
    useEffect(() => {
        if (workflowCreationCompleted || workflowCreationError) {
            console.log('Workflow creation finished:', {
                completed: !!workflowCreationCompleted,
                error: !!workflowCreationError,
                activeOrchestrationId
            });
            
            // Clear states but keep subscription active until component unmounts
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            
            // If we were using a wildcard subscription, clean it up
            if (activeOrchestrationId === '*') {
                setActiveOrchestrationId(undefined);
            }
        }
    }, [workflowCreationCompleted, workflowCreationError]);

    // Cleanup subscription when component unmounts
    useEffect(() => {
        return () => {
            console.log('Cleaning up workflow events context');
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            setWorkflowCreationCompleted(undefined);
            setWorkflowCreationError(undefined);
            setActiveOrchestrationId(undefined);
        };
    }, []);

    const clearAllTasks = () => {
        if (workflowContext) {
            workflowContext.setAgentTasks((prevTasks) =>
                prevTasks.map((task) => ({
                    ...task,
                    processingState: undefined,
                    output: undefined,
                    processingSteps: [],
                    currentStep: undefined
                }))
            );
        }
    };

    const markAllTasksCompleted = () => {
        if (workflowContext) {
            workflowContext.setAgentTasks((prevTasks) =>
                prevTasks.map((task) => ({
                    ...task,
                    processingState: "completed",
                }))
            );
        }
    };

    const handleWorkflowEvent = (event: VurveyOrchestrationEvent) => {
        // If this is a completion event, handle it immediately and stop
        if (event.type === "WorkflowCreationCompleted") {
            setWorkflowCreationCompleted(event.data);
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            // Don't stop subscription here, let it continue for orchestration events
            return;
        }

        // For all other events, only process if we haven't completed yet
        if (workflowCreationCompleted) {
            console.log('Skipping event - workflow already completed:', event.type);
            return;
        }

        switch (event.type) {
        case "WorkflowCreationStarted": {
            setWorkflowCreationStarted(event.data);
            setWorkflowCreationError(undefined);
            setWorkflowCreationCompleted(undefined);
            break;
        }
        case "AgentCreationProgress": {
            setAgentCreationProgress(event.data);
            break;
        }
        case "WorkflowCreationError": {
            setWorkflowCreationError(event.data);
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            updateToast({
                description: `Error creating workflow: ${event.data.error}`,
                type: "failure",
            });
            break;
        }
        case "OrchestrationStarted": {
            clearAllTasks();
            updateToast({description: "Workflow started", type: "informational"});
            break;
        }
        case "OrchestrationCompleted": {
            if (workflowContext) {
                workflowContext.setRunInProgress(false);
                workflowContext.setReportUrl(event.data.reportUrl ?? "");
                workflowContext.setReportWordUrl(event.data.reportWordUrl ?? "");
                workflowContext.refetchHistory();
                setIsGeneratingReport(false);
                markAllTasksCompleted();
            }
            updateToast({
                description: (
                    <>
                        Workflow completed. You can now download the results at{" "}
                        <a href={event.data.reportUrl ?? ""} target="_blank" rel="noopener noreferrer">
                            Report
                        </a>
                    </>
                ),
                type: "success",
                displayTime: 30000,
            });
            // Now we can safely stop the subscription
            setActiveOrchestrationId(undefined);
            break;
        }
        case "OrchestrationError": {
            updateToast({
                description: `Workflow error: ${event.data.error}`,
                type: "failure",
                displayTime: 10000
            });
            if (workflowContext) {
                workflowContext.setRunInProgress(false);
                setIsGeneratingReport(false);
                workflowContext.refetchHistory();
                workflowContext.setAgentTasks((prevTasks) =>
                    prevTasks.map((task) =>
                        task.processingState === "processing"
                            ? {...task, processingState: "error", output: "Task failed due to workflow error"}
                            : task
                    )
                );
            }
            break;
        }
        case "TaskError": {
            const taskId = event.data.taskId;
            const error = event.data.error;
            if (workflowContext) {
                workflowContext.setAgentTasks((prevTasks) =>
                    prevTasks.map((task) =>
                        task.id === taskId
                            ? {...task, processingState: "error", output: `Error: ${error}`}
                            : task
                    )
                );
            }
            updateToast({
                description: `Task error: ${error}`,
                type: "failure",
                displayTime: 10000
            });
            break;
        }
        case "TaskOutputGenerated": {
            updateOutputState(event);
            break;
        }
        case "ReportGeneratingStarted": {
            setIsGeneratingReport(true);
            markAllTasksCompleted();
            break;
        }
        case "ReportGeneratingCompleted": {
            setIsGeneratingReport(false);
            if (workflowContext) {
                workflowContext.setReportUrl(event.data.reportUrl ?? "");
                workflowContext.setReportWordUrl(event.data.reportWordUrl ?? "");
            }
            break;
        }
        case "TaskStarted":
            updateTaskStarted(event);
            break;
        case "TaskCompleted":
            updateTaskCompleted(event);
            break;
        case "TaskUpdated":
            updateTaskProcessingState(event);
            break;
        }
    };

    const updateOutputState = (event: TaskOutputGenerated) => {
        if (workflowContext) {
            workflowContext.setAgentTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === event.data.taskId ? {...task, output: event.data.output} : task
                )
            );
        }
    };

    const updateTaskStarted = (event: TaskStarted) => {
        if (workflowContext) {
            workflowContext.setAgentTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === event.data.taskId ? {
                        ...task,
                        processingState: "processing",
                        processingSteps: [{
                            timestamp: Date.now(),
                            message: event.data.processingState || "Task started"
                        }]
                    } : task
                )
            );
        }
        if (carouselContext) {
            carouselContext.scrollToElement(event.data.taskId);
        }
    };

    const updateTaskCompleted = (event: TaskCompleted) => {
        if (workflowContext) {
            workflowContext.setAgentTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === event.data.taskId ? {
                        ...task,
                        processingState: "[COMPLETED]",
                    } : task
                )
            );
        }
    };

    const updateTaskProcessingState = (event: TaskUpdated) => {
        if (workflowContext) {
            workflowContext.setAgentTasks((prevTasks) =>
                prevTasks.map((task) => {
                    if (task.id === event.data.taskId) {
                        const newStep = {
                            timestamp: event.metadata?.timestamp || Date.now(),
                            message: event.data.processingState,
                            sequence: event.metadata?.sequence
                        };

                        const lastStep = task.processingSteps?.[task.processingSteps.length - 1];
                        const isDuplicate = lastStep?.message === newStep.message;

                        // Sort steps by sequence if available, then by timestamp
                        const updatedSteps = [
                            ...(task.processingSteps || []),
                            ...(isDuplicate ? [] : [newStep])
                        ].sort((a, b) => {
                            if (a.sequence && b.sequence) {
                                return a.sequence - b.sequence;
                            }
                            return (a.timestamp || 0) - (b.timestamp || 0);
                        });

                        const isCompleted = event.data.processingState?.toLowerCase().includes("[completed]");
                        const isError = event.data.processingState?.toLowerCase().includes("error");

                        return {
                            ...task,
                            processingSteps: updatedSteps,
                            processingState: isCompleted ? "[COMPLETED]" : 
                                           isError ? "error" : 
                                           "processing"
                        };
                    }
                    return task;
                })
            );
        }
    };

    return (
        <WorkflowEventsContext.Provider value={{
            isGeneratingReport,
            workflowCreationStarted,
            agentCreationProgress,
            workflowCreationCompleted,
            workflowCreationError,
            setActiveOrchestrationId
        }}>
            {children}
        </WorkflowEventsContext.Provider>
    );
};

export const useWorkflowEventsContext = () => {
    const context = useContext(WorkflowEventsContext);
    if (!context) {
        throw new Error("useWorkflowEventsContext must be used within an WorkflowEventsProvider");
    }
    return context;
};
