import {useQuery} from "@apollo/client";
import React, {ReactNode, createContext, useCallback, useEffect, useMemo, useState} from "react";

import {AGENTS_PUBLISHED} from "../../graphql/queries/ai-models-queries";
import {Persona} from "../../models";
import {PersonaStatus} from "@/models/persona";
import {useChatConversationContext} from "./chat-conversation-context";
import {useNavigate, useSearchParams} from "../../route";
import {useWorkspaceContext} from "../../context/workspace-context";
import {ChatConversationMode, useChatConversationModeContext} from "./chat-conversation-mode";

export interface ChatPersonaContextValue {
	isLoading?: boolean;
	isUpdatingDisabled?: boolean;
	personas: Persona[];
	activePersona?: Persona;
	savePersona: (personaId?: string) => Promise<void>;
	refetch: () => Promise<Persona[]>;
}

export const ChatPersonaContext = createContext<ChatPersonaContextValue | undefined>(undefined);

export interface AgentsPublishedResponse {
	publishedAgents: Persona[];
}

const ALLOWED_MODES = [ChatConversationMode.CONVERSATION, ChatConversationMode.REASONING];

export const ChatPersonaContextProvider = ({children}: {children: ReactNode}): React.ReactElement => {
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const {mode: conversationMode} = useChatConversationModeContext();
	const {conversation, isLoading: isLoadingConversation, updateConversation} = useChatConversationContext();
	const {personaId: personaSavedId} = useSearchParams();
	const [activePersona, setActivePersona] = useState<Persona | undefined>();
	const navigate = useNavigate();

	const {
		data: {publishedAgents: personas = []} = {},
		loading: isLoading,
		refetch,
	} = useQuery<AgentsPublishedResponse>(AGENTS_PUBLISHED, {
		variables: {
			workspaceId,
			version: "THUMBNAIL",
			fetchPolicy: "cache-first",
		},
	});

	const handleRefetch = useCallback(async () => {
		const {
			data: {publishedAgents},
		} = await refetch();

		return publishedAgents;
	}, [refetch]);

	const personasTrigger = personas.map(persona => persona.id).join();

	const isUpdatingDisabled = Boolean(
		((isLoadingConversation || isLoading) && workspaceId) ||
		!ALLOWED_MODES.includes(conversationMode)
	);

	const savePersona = useCallback(
		async (personaId?: string): Promise<void> => {
			if (isUpdatingDisabled) {
				throw new Error("Personas updating is disabled");
			}

			let newPersona;

			if (personaId) {
				newPersona = personas.find(persona => persona.id === personaId);

				if (!newPersona) {
					throw new Error("Persona not found");
				}

				if (newPersona.personaStatus !== PersonaStatus.PUBLISHED) {
					throw new Error("Persona is not published");
				}
			}

			setActivePersona(newPersona);
			if (conversation) {
				await updateConversation({
					aiPersonaId: personaId || null,
				});
			} else {
				navigate(
					{
						search: {
							personaId,
						},
					},
					{search: true},
				);
			}
		},
		[conversation?.id, personasTrigger, isUpdatingDisabled, updateConversation, navigate, conversationMode],
	);

	const publishedPersonas = useMemo(
		() => personas.filter(persona => persona.personaStatus === "published"),
		[personasTrigger],
	);

	const savedPersona = useMemo(() => {
		if (!ALLOWED_MODES.includes(conversationMode)) {
			return undefined;
		}
		if (conversation) {
			return conversation.aiPersona;
		}
		if (personaSavedId) {
			return personas.find(persona => persona.id === personaSavedId);
		}
	}, [
		conversation?.aiPersona?.id,
		personasTrigger,
		personaSavedId,
		personas.map(p => p.id).join(),
		conversationMode,
	]);

	useEffect(() => {
		setActivePersona(savedPersona);
	}, [savedPersona]);

	useEffect(() => {
		if (conversation && personaSavedId?.length) {
			navigate(
				{
					search: {
						personaId: personaSavedId,
					},
				},
				{search: true},
			);
		}
	}, [conversation?.id, personaSavedId]);

	return (
		<ChatPersonaContext.Provider
			value={{
				activePersona,
				personas: publishedPersonas,
				isUpdatingDisabled,
				isLoading,
				savePersona,
				refetch: handleRefetch,
			}}
		>
			{children}
		</ChatPersonaContext.Provider>
	);
};

export const useChatPersonaContext = (): ChatPersonaContextValue => {
	const context = React.useContext(ChatPersonaContext);

	if (context === undefined) {
		throw new Error("useChatPersonaContext must be used within a ChatPersonaContextProvider");
	}

	return context;
};

export const useUnsafeChatPersonaContext = (): ChatPersonaContextValue | undefined => {
	return React.useContext(ChatPersonaContext);
};
