import React, {ReactElement, useMemo, useState} from "react";
import {NetworkStatus} from "@apollo/client";

import {AddTrainingSetModal} from "../../../datasets/modals";
import {Button, Caption, DebounceSearch, LoadingContainer, SearchInput} from "../../../shared/v2";
import {CreateCard} from "../../../shared/v2/cards";
import {DatasetCard} from "../../../datasets/components";
import {GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {Link} from "../../../hoc/layout/navigation/link";
import {PlusSmallIcon, SearchIcon} from "../../../icons";
import {TrainingSet} from "../../../models/ai-model";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {useLoadingQuery} from "@/hooks";
import {TablePagination} from "@/shared/components/table/table-pagination";

import styles from "./all-datasets-page.module.scss";

const PAGE_SIZE = 20;

export const AllDatasetsPage = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const [currentPage, setCurrentPage] = useState(0);

	const {
		data,
		handleFetchMore,
		networkStatus,
		fragment
	} = useLoadingQuery(GET_TRAINING_SETS, {
		skip: !workspaceId,
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
		errorPolicy: "all",
		variables: {
			workspaceId,
			limit: pageSize,
			filter: {
				alias: searchValue
			},
			sort: "ALIAS_ASC"
		},
	})

	const handleChangeSearch = (value: string): void => {
		setSearchValue(value);
		setCurrentPage(0);
	}

	const totalCount = useMemo(() => {
		if (!data) return 0;
		return data?.trainingSetsPage.items?.length + data?.trainingSetsPage.remaining;
	}, [data?.trainingSetsPage?.items, data?.trainingSetsPage?.remaining]);

	const startingRow = currentPage === 0 ? currentPage + 1 : (currentPage * pageSize) + 1;
	const endRow = ((currentPage + 1) * pageSize) > totalCount
		? totalCount : (currentPage + 1) * pageSize;

	const pageCount = useMemo(() => {
		return Math.ceil(totalCount / pageSize);
	}, [totalCount, pageSize]);

	const dataLength = useMemo(() => {
		if (!data) return 0;
		return data?.trainingSetsPage.items?.length;
	}, [data?.trainingSetsPage?.items])

	const handlePageSizeChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	const handleNext = (): void => {
		if ((currentPage + 2) * pageSize > dataLength) handleFetchMore(pageSize);
		setCurrentPage(prev => prev + 1);
	};
	const handlePrev = (): void => {
		setCurrentPage(prev => prev - 1);
	};

	const gotoPage = (newPage: number): void => {
		if (currentPage < newPage && (newPage + 1) * pageSize > dataLength) {
			handleFetchMore((newPage - currentPage) * pageSize);
		}
		setCurrentPage(newPage);
	};

	const currentData = useMemo(() => {
		const current = data?.trainingSetsPage.items.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

		return current
	}, [data?.trainingSetsPage.items, currentPage]);


	const renderContent = (): ReactElement => {
		if (networkStatus !== NetworkStatus.ready && !data?.trainingSetsPage.items.length) {
			return <LoadingContainer />;
		}

		return <>
			{networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables ?
				<LoadingContainer /> :
				<>
					<div className={styles.trainingSets}>
						<CreateCard wrapperClassname={styles.cardWrapper}>
							<Button
								style="brand"
								leftIcon={<PlusSmallIcon />}
								onClick={() => setIsAddModalOpen(true)}
							>
								Create Dataset
							</Button>
							<Caption color="text-secondary" type="regular">
								We keep your <Link
									to="https://help.vurvey.com/en/collections/9210870-ai-data-security-compliance"
									type="underline"
									text="data safe"
								/>
							</Caption>
						</CreateCard>

						{(!data && fragment) ||
							(data && currentData && currentData.map((trainingSet: TrainingSet) => (
								<DatasetCard key={trainingSet.id} trainingSet={trainingSet} />
						)))}
					</div>

					<AddTrainingSetModal
						isOpen={isAddModalOpen}
						onClose={() => setIsAddModalOpen(false)}
					/>
				</>
			}
			<TablePagination
				currentPage={currentPage}
				startingRow={startingRow}
				endRow={endRow}
				totalCount={totalCount}
				onPageSizeChange={handlePageSizeChange}
				handleNext={handleNext}
				handlePrev={handlePrev}
				gotoPage={gotoPage}
				pageCount={pageCount}
				pageSize={pageSize}
				disablePagination={networkStatus === NetworkStatus.fetchMore}
			/>
		</>;
	};

	return <div className={styles.fullWidth}>
		<DebounceSearch
			leftIcon={<SearchIcon />}
			size="small"
			placeholder="Search"
			value={searchValue}
			onChange={handleChangeSearch}
			className={styles.searchInput}
		/>

		{renderContent()}
	</div>
};
