import React, {useEffect, useRef, useId} from "react";
import mermaid from "mermaid";

interface MermaidRendererProps {
  content: string;
}

mermaid.initialize({
  startOnLoad: true,
  theme: "default",
  securityLevel: "loose",
});

export const MermaidRenderer: React.FC<MermaidRendererProps> = ({content}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const uniqueId = useId().replace(/:/g, "-"); // Replace colons with dashes as they're not valid in IDs

  useEffect(() => {
    const renderDiagram = async () => {
      if (containerRef.current) {
        try {
          const {svg} = await mermaid.render(`mermaid-diagram-${uniqueId}`, content);
          if (containerRef.current) {
            containerRef.current.innerHTML = svg;
          }
        } catch (error) {
          console.error("Failed to render Mermaid diagram:", error);
          if (containerRef.current) {
            containerRef.current.innerHTML = `<pre>${content}</pre>`;
          }
        }
      }
    };

    renderDiagram();
  }, [content, uniqueId]);

  return <div ref={containerRef} className="mermaid-container" />;
}; 
