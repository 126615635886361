import {gql} from "@apollo/client";
import {GROUNDING_FRAGMENT} from "../fragments/chat";

export const GET_AI_MODELS = gql`
  query GetAiModels($workspaceId: GUID!) {
    workspaceAiModels(workspaceId: $workspaceId) {
      aiModel {
        id
        name
        description
        provider
        status
        task
        modality
        useCase
        modelCategory {
          name
          description
          id
        }
      }
    }
  }
`;

export const GET_AI_MODEL_CATEGORIES = gql`
  query GetAiModelCategories {
    aiModelCategories {
      id
      name
      description
    }
  }
`;

export const GET_TRAINING_SETS = gql`
  query GetTrainingSets(
    $workspaceId: GUID!
    $limit: PositiveInt
    $filter: TrainingSetsFilter
    $cursor: String
    $sort: TrainingSetsSort
  ) {
    trainingSetsPage(workspaceId: $workspaceId, filter: $filter, limit: $limit, cursor: $cursor, sort: $sort) {
      items {
        name
        alias
        description
        id
        files {
          id
          originalFilename
          url
          originalMimetype
          embeddingsGenerationStatus
          tags {
            id
            key
            value
          }
          trainingSetId
        }
        videos {
          id
          originalFilename
          url(version: H264_AAC)
          originalMimetype
          transcodingStatus
          embeddingsGenerationStatus
          tags {
            id
            key
            value
          }
          trainingSetId
        }
      }
      cursor
      remaining
    }
  }
`;

export const GET_TRAINING_SET = gql`
  query GetTrainingSet($trainingSetId: GUID!) {
    trainingSet(id: $trainingSetId) {
      name
      alias
      id
      description
      files {
        id
        originalFilename
        url
        originalMimetype
        embeddingsGenerationStatus
        tags {
          id
          key
          value
        }
        trainingSetId
      }
      videos {
        id
        originalFilename
        url(version: H264_AAC)
        originalMimetype
        transcodingStatus
        embeddingsGenerationStatus
        tags {
          id
          key
          value
        }
        trainingSetId
      }
    }
  }
`;

// Original queries for mobile compatibility
export const GET_CHAT_CONVERSATIONS_LEGACY = gql`
  query GetChatConversationsLegacy(
    $limit: Int
    $workspaceId: GUID!
    $filter: ChatConversationFilterInput
  ) {
    chatConversations(limit: $limit, workspaceId: $workspaceId, filter: $filter) {
      id
      name
      workspaceId
      createdAt
      isPreview
    }
  }
`;

export const GET_AGENT_RECENT_CONVERSATIONS_LEGACY = gql`
  query GetAgentRecentConversationsLegacy(
    $workspaceId: GUID!
  ) {
    chatConversations(limit: 40, workspaceId: $workspaceId) {
      id
      name
      preview {
        images
      }
      aiPersona {
        id
      }
      messages {
        persona {
          id
        }
      }
    }
  }
`;

export const GET_CHAT_CONVERSATIONS_WITH_PREVIEW_LEGACY = gql`
  query GetAllChatConversationsLegacy(
    $workspaceId: GUID!
    $filter: ChatConversationFilterInput
    $limit: Int
  ) {
    chatConversations(workspaceId: $workspaceId, filter: $filter, limit: $limit) {
      id
      name
      createdAt
      isPreview
      preview {
        images
        creator {
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`;

// New paginated queries for web client
export const GET_CHAT_CONVERSATIONS = gql`
  query GetChatConversations(
    $limit: PositiveInt
    $workspaceId: GUID!
    $filter: ChatConversationsFilter
    $sort: ChatConversationsSort
    $cursor: String
  ) {
    paginatedChatConversations(
      limit: $limit,
      workspaceId: $workspaceId,
      filter: $filter,
      sort: $sort,
      cursor: $cursor
    ) {
      items {
        id
        name
        workspaceId
        createdAt
        isPreview
      }
      cursor
      remaining
    }
  }
`;

export const GET_AGENT_RECENT_CONVERSATIONS = gql`
  query GetAgentRecentConversations(
    $workspaceId: GUID!
    $sort: ChatConversationsSort
  ) {
    paginatedChatConversations(
      limit: 40,
      workspaceId: $workspaceId,
      sort: $sort
    ) {
      items {
        id
        name
        preview {
          images
        }
        aiPersona {
          id
        }
        messages {
          persona {
            id
          }
        }
      }
      cursor
      remaining
    }
  }
`;

export const GET_CHAT_CONVERSATIONS_WITH_PREVIEW = gql`
  query GetAllChatConversations(
    $workspaceId: GUID!
    $filter: ChatConversationsFilter
    $limit: PositiveInt
    $sort: ChatConversationsSort
    $cursor: String
  ) {
    paginatedChatConversations(
      workspaceId: $workspaceId,
      filter: $filter,
      limit: $limit,
      sort: $sort,
      cursor: $cursor
    ) {
      items {
        id
        name
        createdAt
        isPreview
        preview {
          images
          creator {
            firstName
            lastName
          }
          content
          createdAt
        }
      }
      cursor
      remaining
    }
  }
`;

export const GET_CHAT_CONVERSATION = gql`
  query GetChatConversation($id: GUID!) {
    chatConversation(id: $id) {
      id
      name
      workspaceId
      isPreview
      surveys {
        id
        name
      }
      files {
        id
        originalFilename
        url
        originalMimetype
        embeddingsGenerationStatus
        tags {
          id
          key
          value
        }
        trainingSetId
      }
      videos {
        id
        originalFilename
        url(version: H264_AAC)
        originalMimetype
        transcodingStatus
        embeddingsGenerationStatus
        tags {
          id
          key
          value
        }
        trainingSetId
      }
      messages {
        id
        content
        conversationId
        persona {
          id
          name
          voiceId
          voiceName
          description
          isVurvey
          personaCategory {
            id
            name
          }
          personaStatus
          picture {
            id
            url(version: THUMBNAIL)
          }
          avatarUri
        }
        role
        type
        position
        likedByUsers {
          id
        }
        dislikedByUsers {
          id
        }
        groundingData {
          ...GroundingFields
        }
      }
      trainingSets {
        id
        name
        alias
      }
      aiPersona {
        id
        name
        voiceId
        voiceName
        description
        isVurvey
        personaCategory {
          name
        }
        picture {
          id
          url(version: THUMBNAIL)
        }
        avatarUri
      }
    }
  }
  ${GROUNDING_FRAGMENT}
`;

export const GET_PERSONAS = gql`
  query AiPersonasForWorkspace(
    $workspaceId: GUID!
    $version: PersonaPictureVersion!
    $personaStatus: PersonaStatus
  ) {
    aiPersonasForWorkspace(workspaceId: $workspaceId, personaStatus: $personaStatus) {
      id
      name
      description
      instructions
      personaStatus
      personaCategory {
        id
        name
      }
      picture {
        id
        url(version: $version)
      }
      avatarUri
      personaType {
        id
        name
      }
      voiceId
      voiceName
      isVurvey
      parsedInstructions @client
      createdAt
      skills {
        id
        name
      }
    }
  }
`;

export const AGENTS_PUBLISHED = gql`
  query AgentsPublished($workspaceId: GUID!) {
    publishedAgents: aiPersonasForWorkspace(
      workspaceId: $workspaceId
      personaStatus: PUBLISHED
    ) {
      id
      name
      description
      picture {
        id
        url(version: THUMBNAIL)
      }
      avatarUri
      isVurvey
      personaStatus
      personaType {
        id
        name
      }
    }
  }
`;

export const AGENTS_PUBLISHED_WITH_TASKS = gql`
  query AiPersonasWithOrchTasks(
    $workspaceId: GUID!
    $version: PersonaPictureVersion!
    $orchestrationId: GUID
  ) {
    aiPersonasForWorkspace(workspaceId: $workspaceId, personaStatus: PUBLISHED) {
      id
      name
      description
      voiceId
      voiceName
      personaCategory {
        id
        name
      }
      picture {
        id
        url(version: $version)
      }
      avatarUri
      tasks(orchestrationId: $orchestrationId) {
        id
        name
        instructions
        parsedInstructions @client
        index
        tools {
          id
          name
          code
        }
      }
      isVurvey
      personaStatus
      personaType {
        id
        name
      }
    }
  }
`;

export const AI_PERSONA_BY_ID = gql`
  query AiPersonaById(
    $personaId: GUID!
    $workspaceId: GUID!
    $version: PersonaPictureVersion!
  ) {
    aiPersonaById(personaId: $personaId, workspaceId: $workspaceId) {
      id
      name
      description
      instructions
      voiceId
      voiceName
      personaType {
        id
        name
      }
      personaCategory {
        id
        name
      }
      picture {
        id
        url(version: $version)
      }
      avatarUri
      isVurvey
      personaStatus
      parsedInstructions @client
      skills {
        id
      }
    }
  }
`;

export const GET_AI_TOOLS = gql`
  query GetAiTools($limit: PositiveInt, $cursor: String) {
    aiTools(limit: $limit, cursor: $cursor) {
      items {
        id
        name
        code
      }
    }
  }
`;

export const GET_AI_PERSONA_CATEGORIES = gql`
  query AiPersonaCategories {
    aiPersonaCategories {
      id
      name
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetActiveCampaigns($workspaceId: GUID!) {
    campaigns: workspaceSurveys(workspaceId: $workspaceId, filter: {status: [CLOSED, OPEN], withResponsesOnly: true}) {
      items {
        id
        name
        questionCount
        completedResponseCount
        responseCount
        totalVideoDuration
      }
    }
  }
`;

export const AI_PERSONA_TYPES = gql`
  query AiPersonaTypes {
    aiPersonaTypes {
      id
      name
      description
      outputType {
        id
        code
      }
    }
  }
`;

export const PROMPT_TEMPLATES = gql`
  query PromptTemplates {
    promptTemplates {
      id
      name
      description
      template
      type
      workspaceId
    }
  }
`;

export const SYSTEM_AGENTS = gql`
  query SystemAgents {
    systemAgents {
      id
      name
      code
      description
    }
  }
`;

export const AI_SKILLS = gql`
  query AiSkills {
    aiSkills {
      id
      name
      description
    }
  }
`;

export const FILE_TAG_KEYS = gql`
  query FileTagKeys($workspaceId: GUID!) {
    fileTagKeys(workspaceId: $workspaceId) {
      value
      id
    }
  }
`;
