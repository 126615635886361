import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body, Dropdown, DropdownItem} from "@/shared/v2";
import {ChatConversationMode, ChatLayoutMode, useChatConversationModeContext, useChatLayoutModeContext} from "@/context/chat-contexts";
import {AiChatMessageIcon, ChevronDownIcon, ChevronRightIcon, ModelsIcon, SparkAiStarsIcon} from "@/icons";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./inspiration-button.module.scss";

const cx = classNames.bind(styles);

export const InspirationButton = (): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const {mode: layout} = useChatLayoutModeContext();
  const isHomeMode = layout === ChatLayoutMode.HOME;
  const {mode: conversationMode, setMode: setConversationMode} = useChatConversationModeContext();

  const icons = {
    [ChatConversationMode.CONVERSATION]: <SparkAiStarsIcon key={0} className={cx("icon", "mode")} />,
    [ChatConversationMode.REASONING]: <AiChatMessageIcon key={0} className={cx("icon", "mode")} />,
  };

  const labels = {
    [ChatConversationMode.CONVERSATION]: "Manual",
    [ChatConversationMode.REASONING]: "Agentic Tools",
  }

  const descriptions = {
    [ChatConversationMode.CONVERSATION]: "In Manual Mode, users have full control over AI tool selection. The AI will only perform actions when explicitly instructed—whether it’s searching the web, generating images, or interacting with social platforms. This mode is ideal for users who prefer a hands-on approach, selecting each tool based on their specific needs. Additionally, users can choose an agent for the conversation to tailor responses accordingly.",
    [ChatConversationMode.REASONING]: "Agentic Tools Mode enhances automation by intelligently selecting the necessary tools based on the user’s prompt. Whether it requires web searches, social interactions, or image generation, the AI autonomously determines and executes the most relevant actions. Like Manual Mode, users can also personalize the conversation by selecting a specific AI agent, ensuring a customized and dynamic interaction. This mode is perfect for those who want a more seamless and intelligent assistant while retaining the ability to define the AI’s personality.",
  }

  const dropdownItems = Object.values(ChatConversationMode).map((mode) => ({
    label: labels[mode],
    icon: icons[mode],
    tooltip: descriptions[mode],
    active: conversationMode === mode,
    onClick: () => setConversationMode(mode),
  } as DropdownItem));

  return (
    <Dropdown
      items={dropdownItems}
      position={isHomeMode ? "bottom" : "right"}
      space={isHomeMode ? 14 : 12}
      trigger={
        <div className={cx("inspirationButton", {isDarkMode})}>
          {icons[conversationMode]}
          <Body size="s" type="medium">
            {labels[conversationMode]}
          </Body>
          {isHomeMode ?
            <ChevronDownIcon className={cx("icon", "chevron")} /> :
            <ChevronRightIcon className={cx("icon", "chevron")} />
          }
        </div>
      }
    />
  );
}
