import React, {ReactElement, ReactNode} from "react";
import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatEventsContextProvider,
	ChatFollowUpsContextProvider,
	ChatImageContextProvider, ChatMentionsContextProvider,
	ChatMessagesContextProvider,
	ChatLayoutModeContextProvider,
	ChatPersonaContextProvider,
	ChatScrollContextProvider,
	ChatSendQuestionContextProvider,
	ChatSourcesContextProvider,
	ChatToolsContextProvider, ChatConversationModeContextProvider
} from "../../context/chat-contexts";
import {ChatHandlers} from "./handlers";
import {ImageHistoryContextProvider} from "../image-studio/contexts";
import {ChatStateContextProvider} from "@/context/chat-contexts/chat-state-context";

export interface ChatContextsAndHandlersProps {
  children?: ReactNode;
}

export const ChatContextsAndHandlers = ({children}: ChatContextsAndHandlersProps): ReactElement => {
	return (
		<ChatStateContextProvider>
			<ImageHistoryContextProvider>
				<ChatScrollContextProvider>
					<ChatEventsContextProvider>
						<ChatMentionsContextProvider>
							<ChatFollowUpsContextProvider>
								<ChatImageContextProvider>
									<ChatConversationModeContextProvider>
										<ChatConversationContextProvider>
											<ChatPersonaContextProvider>
												<ChatMessagesContextProvider>
													<ChatSourcesContextProvider>
														<ChatCampaignContextProvider>
															<ChatToolsContextProvider>
																<ChatSendQuestionContextProvider>
																	<ChatLayoutModeContextProvider>
																		<ChatHandlers />
																		{children}
																	</ChatLayoutModeContextProvider>
																</ChatSendQuestionContextProvider>
															</ChatToolsContextProvider>
														</ChatCampaignContextProvider>
													</ChatSourcesContextProvider>
												</ChatMessagesContextProvider>
											</ChatPersonaContextProvider>
										</ChatConversationContextProvider>
									</ChatConversationModeContextProvider>
								</ChatImageContextProvider>
							</ChatFollowUpsContextProvider>
						</ChatMentionsContextProvider>
					</ChatEventsContextProvider>
				</ChatScrollContextProvider>
			</ImageHistoryContextProvider>
		</ChatStateContextProvider>
	);
}
