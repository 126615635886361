import React, {MouseEvent, ReactElement, useMemo} from "react"

import {Persona, PersonaStatus, PersonaTransformedStatus} from '../../../../models/persona';
import {Avatar, Body, Button, Subheader} from "../../../../shared/v2";
import {SendArrowChatIcon, VurveyYellowLogoIcon} from "../../../../icons";
import {StatusIndicator} from "../../../../shared/v2/status-indicator";

import styles from "./persona-card-content.module.scss";
import {useNavigate} from "../../../../route";
import {useChatConversationContext} from "../../../../context/chat-contexts/chat-conversation-context";

export interface PersonaCardContentProps {
  persona: Persona;
  actionsDropdown?: ReactElement | null;
  isHovered?: boolean;
}

export const transformPersonaStatus = (status: string): PersonaTransformedStatus => {
	if (status === PersonaStatus.PUBLISHED) {
		return PersonaTransformedStatus.ACTIVE;
	}
	return PersonaTransformedStatus.INACTIVE;
}

export const PersonaCardContent = ({persona, actionsDropdown, isHovered}: PersonaCardContentProps): ReactElement => {
	const navigate = useNavigate();
	const {createConversation} = useChatConversationContext();

	const openInCopilot = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
		e.stopPropagation();
		const conversation = await createConversation({
			aiPersonaId: persona.id,
			name: `Chat with ${persona.name}`
		});
		navigate(
			{
				pathname: "/workflow/conversation",
				search: {canvasId: conversation.id},
			},
			{workspace: true},
		);
	};

	const isActive = useMemo(() => {
		return persona.personaStatus === PersonaStatus.PUBLISHED;
	}, [persona.personaStatus]);

	return <div className={styles.content}>
		<div className={styles.topSection}>
			<Avatar
				size="xl"
				url={persona.picture?.url || persona.avatarUri}
				additionalContent={<StatusIndicator active={isActive} hovered={isHovered} />}
				firstName={persona.name}
			/>

			<div className={styles.actionsWrapper}>
				{isActive &&
          <Button onClick={openInCopilot} variant="outlined" size="small" leftIcon={<SendArrowChatIcon />}>Message</Button>
				}

				{actionsDropdown}
			</div>
		</div>

		<div className={styles.textSection}>
			<div className={styles.titleContainer}>
				<Subheader className={styles.name} type="semibold">{persona.name}</Subheader>

				<Body className={styles.description} size="s" color="text-secondary">{persona.personaType.name}</Body>
			</div>

			<div className={styles.skills}>
				{persona.skills.length ?
					<Body color="text-secondary" size="xs">
						<span className={styles.semibold}>Tags: </span>
						{persona.skills?.map(persona => persona.name).join(", ")}
					</Body>
					: null
				}
			</div>

			{persona.isVurvey &&
        <VurveyYellowLogoIcon className={styles.isVurveyIcon} />
			}
		</div>
	</div>
}
