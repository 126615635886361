import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {AddUserIcon, GeminiIcon, ImagePictureIcon, OpenAiIcon, SocialInstagramIcon, SocialLinkedinIcon, SocialRedditIcon, SocialTiktokIcon, SocialXTwitterIcon, SocialYoutubeIcon, StableDiffusionIcon, WebGlobeIcon, WebGlobeMouseCoursorIcon, WebGlobePlusAdvancedIcon} from "@/icons";
import {ButtonIcon} from "@/shared/v2";
import {CHAT_COMMANDS} from "@/shared/constants/constants";
import {ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP, ChatImageTool, ChatSocialTool, ChatTool, ChatToolValue, ChatWebTool, useChatConversationModeContext, useChatPersonaContext, useChatToolsContext} from "@/context/chat-contexts";
import {InspirationButton} from "./inspiration-button";
import {OptionDropdown} from "./option-dropdown";
import {SelectPersonaModal} from "@/canvas/select-persona-modal";
import {useModalActions} from "@/hooks";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./toolbar.module.scss";

const cx = classNames.bind(styles);

const isEnumValue = (value: string, enumType: any): boolean => {
  return Object.values(enumType).includes(value);
}

export const Toolbar = (): ReactElement => {
  const {
    activePersona: agent,
    isUpdatingDisabled: isPersonaUpdatingDisabled,
  } = useChatPersonaContext();
  const {mode: conversationMode} = useChatConversationModeContext();
  const {isDarkMode} = useThemeMode();
  const {
    tool: activeTool,
    socialTool,
    imageTool,
    webTool,
    setSocialTool,
    setImageTool,
    setWebTool,
    setTool,
  } = useChatToolsContext();

  const {
		value: isSelectPersonaModalOpen,
		open: openSelectPersonaModal,
		close: closeSelectPersonaModal,
	} = useModalActions();

  function addDisabledMap<T extends {value: ChatToolValue}>(options: T[]): T[] {
    return options.map(option => ({
      ...option,
      disabled: !ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP[conversationMode].includes(option.value)
    }))
  }

  const socialOptions = addDisabledMap([
    {
      value: ChatSocialTool.TIKTOK,
      label: "TikTok",
      icon: <SocialTiktokIcon className={styles.iconTiktok} />,
      description: "Allows you to pass in a hashtag to search TikTok to gather and understand the insights, sentiment, top vidoes, etc in order to understand what people are saying or posting about a particular topic. Example: #slugging",
      buttonClassName: styles.buttonTiktok,
    },
    {
      value: ChatSocialTool.REDDIT,
      label: "Reddit",
      icon: <SocialRedditIcon className={styles.iconReddit} />,
      description: "Allows you to pass in a subreddit, keyword, or topic to search Reddit to gather and analyze insights, sentiment, trending posts, and discussions to understand what people are saying about a particular subject. Example: r/SkincareAddiction",
      buttonClassName: styles.buttonReddit
    },
    {
      value: ChatSocialTool.LINKEDIN,
      label: "LinkedIn",
      icon: <SocialLinkedinIcon className={styles.iconLinkedin} />,
      description: "Allows you to pass in a keyword, company name, or topic to search LinkedIn to analyze insights, sentiment, trending posts, and professional conversations around a specific topic. Example: ‘dermatology trends’ or ‘beauty industry innovations.’",
      buttonClassName: styles.buttonLinkedin
    },
    {
      value: ChatSocialTool.YOUTUBE,
      label: "YouTube",
      icon: <SocialYoutubeIcon className={styles.iconYoutube} />,
      description: "Allows you to pass in a keyword or topic to search YouTube to gather and analyze video insights, sentiment, top videos, and audience feedback to understand what creators and viewers are saying about a particular subject. Example: ‘slugging skincare routine’ or ‘hydration tips.’",
      buttonClassName: styles.buttonYoutube
    },
    {
      value: ChatSocialTool.X_TWITTER,
      label: "X/Twitter",
      icon: <SocialXTwitterIcon className={styles.iconXTwitter} />,
      description: "Allows you to pass in a hashtag, keyword, or topic to search X/Twitter to collect and analyze tweets, insights, sentiment, and top posts to understand trends and public opinions around a specific topic. Example: #slugging or ‘skin hydration.’",
      buttonClassName: styles.buttonXTwitter
    },
    {
      value: ChatSocialTool.INSTAGRAM,
      label: "Instagram",
      icon: <SocialInstagramIcon className={styles.iconInstagram} />,
      description: "Allows you to enter a hashtag, keyword, or topic to search Instagram for posts, analyze engagement, sentiment, trends, and top content. This helps you gain insights into public opinions and emerging conversations around a specific topic. Example: #glassskin or ‘moisturizing routine.’",
      buttonClassName: styles.buttonInstagram
    }
  ]);

  const imageOptions = addDisabledMap([
    {
      value: ChatImageTool.ALL,
      label: "All",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/image")?.description,
      icon: <ImagePictureIcon className={styles.iconImageAll} />
    },
    {
      value: ChatImageTool.STABLE_DIFFUSION,
      label: "Stable Diffusion",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/imagesd")?.description,
      icon: <StableDiffusionIcon className={styles.iconImageSD} />
    },
    {
      value: ChatImageTool.DALLE,
      label: "OpenAI DALL-E",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/imagede")?.description,
      icon: <OpenAiIcon className={styles.iconImageDE} />
    },
    {
      value: ChatImageTool.IMAGEN,
      label: "Google Imagen",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/imagen")?.description,
      icon: <GeminiIcon className={styles.iconImageImagen} />
    },
  ]);

  const webOptions = addDisabledMap([
    {
      value: ChatWebTool.SCRAPE,
      label: "Scrape",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/scrape")?.description,
      icon: <WebGlobeMouseCoursorIcon className={styles.iconScrape} />
    },
    {
      value: ChatWebTool.SCRAPE_ADVANCED,
      label: "Scrape advanced",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/scrape_advanced")?.description,
      icon: <WebGlobePlusAdvancedIcon className={styles.iconScrapeAdvanced} />
    },
    {
      value: ChatWebTool.WEB,
      label: "Web",
      description: CHAT_COMMANDS.find(cmd => cmd.command === "/web")?.description,
      icon: <WebGlobeIcon className={styles.iconWeb} />
    }
  ]);

  const handleToolSelect = (tool: ChatTool | null) => {
    setTool(prev => prev === tool ? null : tool);
  };

  const handleAgentSelect = () => {
    if (isPersonaUpdatingDisabled) {
      return;
    }

    const isActive = activeTool === ChatTool.AGENT;

    setTool(ChatTool.AGENT);

    if (!agent || isActive) {
      openSelectPersonaModal();
    }
  };

  const handleSocialSelect = () => handleToolSelect(ChatTool.SOCIAL);
  const handleImageSelect = () => handleToolSelect(ChatTool.IMAGE);
  const handleWebSelect = () => handleToolSelect(ChatTool.WEB);

  const handleSetSocialTool = (tool: ChatSocialTool) => {
    setSocialTool(tool);
    if (!isEnumValue(activeTool as string, ChatSocialTool)) {
      handleToolSelect(ChatTool.SOCIAL);
    }
  }

  const handleSetImageTool = (tool: ChatImageTool) => {
    setImageTool(tool);
    if (!isEnumValue(activeTool as string, ChatImageTool)) {
      handleToolSelect(ChatTool.IMAGE);
    }
  }

  const handleSetWebTool = (tool: ChatWebTool) => {
    setWebTool(tool);
    if (!isEnumValue(activeTool as string, ChatWebTool)) {
      handleToolSelect(ChatTool.WEB);
    }
  }

  const isOptionDisabled = (options: typeof socialOptions | typeof imageOptions | typeof webOptions) => {
    return !options.some(({value}) => ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP[conversationMode].includes(value));
  }

  return (
    <>
      <div className={cx("toolbar", {isDarkMode})}>
        <InspirationButton />
        <ButtonIcon
          className={cx(styles.buttonAgent, {active: activeTool === ChatTool.AGENT})}
          icon={<AddUserIcon className={cx("iconAgent", {active: activeTool === ChatTool.AGENT})} />}
          onClick={handleAgentSelect}
          disabled={isPersonaUpdatingDisabled}
        />
        <OptionDropdown
          isActive={isEnumValue(activeTool as string, ChatSocialTool)}
          activeClass={styles.active}
          selected={socialTool}
          onClick={handleSocialSelect}
          onSelect={handleSetSocialTool}
          options={socialOptions}
          disabled={isOptionDisabled(socialOptions)}
        />
        <OptionDropdown
          className={styles.buttonImage}
          isActive={isEnumValue(activeTool as string, ChatImageTool)}
          activeClass={styles.active}
          selected={imageTool}
          onClick={handleImageSelect}
          onSelect={handleSetImageTool}
          options={imageOptions}
          disabled={isOptionDisabled(imageOptions)}
        />
        <OptionDropdown
          isActive={isEnumValue(activeTool as string, ChatWebTool)}
          className={styles.buttonWeb}
          activeClass={styles.active}
          selected={webTool}
          onClick={handleWebSelect}
          onSelect={handleSetWebTool}
          options={webOptions}
          disabled={isOptionDisabled(webOptions)}
        />
      </div>

      <SelectPersonaModal
				isOpen={isSelectPersonaModalOpen}
				onClose={closeSelectPersonaModal}
			/>
    </>
  );
}
