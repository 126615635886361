import {useLocation, useParams} from "react-router";
import {useQuery, useMutation} from "@apollo/client";
import React, {createContext, useContext, useState, ReactNode, useEffect, useMemo} from "react";

import {
	AiOrchestration,
	AiOrchestrationData,
	AiOrchestrationHistory,
	AiPersonaTask,
	AiOrchestrationHistoryData
} from "../../models/ai-orchestration";
import {CREATE_PERSONA_TASK, DELETE_PERSONA_TASK, RUN_WORKFLOW, UPDATE_PERSONA_TASK} from "../../graphql/mutations/ai-mutations";
import {GET_AI_ORCHESTRATION, GET_AI_ORCHESTRATION_HISTORY} from "../../graphql/queries/ai-orchestration-queries";
import {Survey} from "../../models/survey";
import {TrainingSet} from "../../models";
import {useToastContext} from "../toast-context";
import {Campaign} from "../../models/ai-model";
import {GET_CAMPAIGNS, GET_TRAINING_SETS} from "../../graphql/queries/ai-models-queries";
import {useWorkspaceContext} from "../workspace-context";

interface WorkflowContextProps {
  workflow: AiOrchestration | undefined;
  agentTasks: AiPersonaTask[];
  activeAgentTasks: AiPersonaTask[];
  isWorkflowDirty: boolean;
  isSaving: boolean;
	aiHistory: AiOrchestrationHistory[];
	currentHistory: AiOrchestrationHistory | undefined;
	setCurrentHistory: (history: AiOrchestrationHistory | undefined) => void;
  setAgentTasks: React.Dispatch<React.SetStateAction<AiPersonaTask[]>>;
  handleSaveWorkflow: () => Promise<void>;
  currentSources: (Survey | TrainingSet)[];
  isLoadingWorkflow: boolean;
	activeHistoryTasks: AiPersonaTask[];
	refetchHistory: () => void;
	isOnHistoryTab: boolean;
	reportUrl: string | undefined;
	reportWordUrl: string | undefined;
	setReportUrl: React.Dispatch<React.SetStateAction<string>>;
	setReportWordUrl: React.Dispatch<React.SetStateAction<string>>;
	currentHistorySources: (Survey | TrainingSet)[];
	isHistoryLoading: boolean;
	isWorkflowRunning: boolean;
	runInProgress: boolean;
	setRunInProgress: React.Dispatch<React.SetStateAction<boolean>>;
	currentHistoryRun: AiOrchestrationHistory | undefined;
}

const WorkflowContext = createContext<WorkflowContextProps | undefined>(undefined);

export const WorkflowProvider = ({children}: { children: ReactNode }) => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const location = useLocation();
	const {updateToast} = useToastContext();
	const {workflowId} = useParams();
	const [agentTasks, setAgentTasks] = useState<AiPersonaTask[]>([]);
	const [isSaving, setIsSaving] = useState(false);
	const [currentHistory, setCurrentHistory] = useState<AiOrchestrationHistory | undefined>(undefined);
	const [activeHistoryTasks, setActiveHistoryTasks] = useState<AiPersonaTask[]>([]);
	const [reportUrl, setReportUrl] = useState<string>("");
	const [reportWordUrl, setReportWordUrl] = useState<string>("");
	const [runInProgress, setRunInProgress] = useState(false);

	const handleSetCurrentHistory = (history: AiOrchestrationHistory | undefined) => {
		setCurrentHistory(history);
	}

	const {data: workflowData, loading: isLoadingWorkflow, refetch} = useQuery<AiOrchestrationData>(
		GET_AI_ORCHESTRATION,
		{
			variables: {id: workflowId},
			skip: !workflowId,
			fetchPolicy: "cache-first",
		}
	);

	const {data: workflowHistoryData, loading: isHistoryLoading, refetch: refetchHistory} = useQuery<AiOrchestrationHistoryData>(GET_AI_ORCHESTRATION_HISTORY, {
		variables: {
			orchestrationId: workflowId
		},
		skip: !workflowId,
		fetchPolicy: "network-only",
	});

	const currentHistoryRun = useMemo(() => {
		return workflowHistoryData?.aiOrchestrationHistory?.find(
			(history) => !history.completedAt && history.status !== 'failed'
		);
	}, [workflowHistoryData?.aiOrchestrationHistory]);

	const isWorkflowRunning = useMemo(() => {
		return Boolean(currentHistoryRun) || runInProgress;
	}, [currentHistoryRun, runInProgress]);

	// Start polling when workflow is running
	useEffect(() => {
		if (isWorkflowRunning) {
			const interval = setInterval(refetchHistory, 1000);
			return () => clearInterval(interval);
		}
	}, [isWorkflowRunning, refetchHistory]);

	const {
		data: campaignsData,
	} = useQuery<{
		campaigns: {
			items: Campaign[];
			cursor: string;
			remaining: number;
		};
	}>(GET_CAMPAIGNS, {
		variables: {
			workspaceId,
		},
		fetchPolicy: "cache-first",
	});

	const {
		data: { trainingSetsPage: { items: trainingSets = [] } = {} } = {},
	} = useQuery<{
		trainingSetsPage: {
			items: TrainingSet[];
		};
	}>(GET_TRAINING_SETS, {
		variables: {
			workspaceId,
		},
		fetchPolicy: "cache-first",
	});

	const [createTask] = useMutation(CREATE_PERSONA_TASK);
	const [updateTask] = useMutation(UPDATE_PERSONA_TASK);
	const [deleteTask] = useMutation(DELETE_PERSONA_TASK);

	const workflow = useMemo(() => workflowData?.aiOrchestration, [workflowData]);

	const isOnHistoryTab = useMemo(() => {
		return location.pathname.includes("history");
	}, [location.pathname]);

	useEffect(() => {
		if (workflowHistoryData?.aiOrchestrationHistory?.length) {
			const currentWorkflowHistory = workflowHistoryData.aiOrchestrationHistory[0];
			setCurrentHistory(currentWorkflowHistory);
		}
	}, [workflowHistoryData?.aiOrchestrationHistory]);

	useEffect(() => {
		if (currentHistory) {
			const newAgentsFromHistory = currentHistory?.outputHistoryTasks.map((historyTask, index) => {
					return {
						id: Math.random().toString(),
						index,
						instructions: null,
						persona: historyTask.persona,
						output: historyTask.output,
						task: {
							id: historyTask.taskId,
							output: historyTask.output,
							taskPrompt: historyTask.taskPrompt,
						},
					} as AiPersonaTask;
				}
			)
			setActiveHistoryTasks(newAgentsFromHistory);
		}
	}, [currentHistory] )

	useEffect(() => {
		if (workflow?.aiPersonaTasks) {
			const newAgents = workflow.aiPersonaTasks.map((task) => {
				return {...task};
			});
			const sortedAgents = newAgents.sort((a, b) => (a.index || 0) - (b.index || 0));
			setAgentTasks(sortedAgents);
		}
	}, [workflow?.aiPersonaTasks]);

	const activeAgentTasks = agentTasks?.filter(a => a.operation !== "DELETE");

	const isWorkflowDirty = useMemo(() => {
		return agentTasks?.some(a => a.operation === "CREATE" || a.operation === "UPDATE" || a.operation === "DELETE");
	}, [agentTasks]);


	const handleSaveWorkflow = async () => {
		if (!isWorkflowDirty) {
			return;
		}
		setIsSaving(true);
		const agentsToRemove = agentTasks.filter(a => a.operation === "DELETE");

		const removingPromises = agentsToRemove.map((agent) => {
			return deleteTask({variables: {id: agent.id}});
		});

		const createUpdatePromises = activeAgentTasks.map((agent, index) => {
			if (agent.id.startsWith("NEW")) {
				return createTask({
					variables: {
						input: {
							index: index,
							personaId: agent.persona?.id || "",
							orchestrationId: workflowId,
							task: agent.task,
							smartPromptEnabled: agent.smartPromptEnabled,
						},
					},
				});
			} else {
				return updateTask({
					variables: {
						input: {
							id: agent?.id || "",
							index: agent.index,
							task: {
								taskPrompt: agent.task?.taskPrompt || "",
							},
							smartPromptEnabled: agent.smartPromptEnabled,
						},
					},
				});
			}
		});

		await Promise.all([...removingPromises, ...createUpdatePromises]);

		await refetch();
		setIsSaving(false);
		updateToast({
			type: "success",
			description: "Workflow saved successfully",
		})
	};

	const currentSources = useMemo(() => {
		const surveys = workflow?.surveys || [];
		const trainingSets = workflow?.trainingSets || [];

		return [...surveys, ...trainingSets];
	}, [workflow?.trainingSets, workflow?.surveys]);

	const currentHistorySources = useMemo(() => {
		const surveys = currentHistory?.sources?.surveyIds?.map((id) => {
			return {
				id,
				name: campaignsData?.campaigns?.items.find((c) => c.id === id)?.name || "",
			} as Survey;
		}
		) || [];

		const currentTrainingSets = currentHistory?.sources?.trainingSetIds?.map((id) => {
			return {
				id,
				alias: trainingSets.find((t) => t.id === id)?.alias || "",
			} as TrainingSet;
		}) || [];

		return [surveys, currentTrainingSets];

	}, [currentHistory?.sources?.trainingSetIds, currentHistory?.sources?.surveyIds, campaignsData?.campaigns?.items, trainingSets]);

	return (
		<WorkflowContext.Provider
			value={{
				workflow,
				agentTasks,
				isWorkflowDirty,
				isSaving,
				setAgentTasks,
				handleSaveWorkflow,
				currentSources,
				isLoadingWorkflow,
				activeAgentTasks,
				aiHistory: workflowHistoryData?.aiOrchestrationHistory || [],
				currentHistory,
				setCurrentHistory: handleSetCurrentHistory,
				activeHistoryTasks,
				refetchHistory,
				isOnHistoryTab,
				reportUrl,
				reportWordUrl,
				setReportUrl,
				setReportWordUrl,
				currentHistorySources: currentHistorySources.flat(),
				isHistoryLoading,
				isWorkflowRunning,
				runInProgress,
				setRunInProgress,
				currentHistoryRun,
			}}
		>
			{children}
		</WorkflowContext.Provider>
	);
};

export const useWorkflowContext = () => {
	const context = useContext(WorkflowContext);
	if (!context) {
		throw new Error("useWorkflowContext must be used within a WorkflowProvider");
	}
	return context;
};
