import classNames from "classnames/bind";
import React, {cloneElement, ReactElement, useMemo, useState} from "react";

import {CheckmarkIcon, ChevronDownIcon} from "../../../../icons";
import {Dropdown} from "../../../../shared/v2/dropdown";
import {InputElements} from "../input-elements";
import {InputProps} from "../input";
import {useThemeMode} from "../../../../context/theme-mode-context";

import inputStyles from "../input/input.module.scss";
import styles from "./select-input.module.scss";
import {SearchInput} from "..";

const cx = classNames.bind(inputStyles);
const selectCx = classNames.bind(styles);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Option<T = any> {
	id: T;
	name: string;
  tooltip?: string;
	icon?: ReactElement;
};

export interface SelectInputProps extends Omit<
	InputProps<string>,
	"type" | "value" | "rightIcon" | "onEnter" | "onBlur"
> {
	value?: string | undefined;
	options: Option[];
	onChange: (value: string) => void;
	onSearch?: (value: string, option: Option) => boolean;
}

export const SelectInput = ({
	className,
	value,
	size = "small",
	variant = "default",
	label,
	hintText,
	validation,
	options,
	leftIcon,
	onSearch,
	onChange,
	...props
}: SelectInputProps): ReactElement => {
	const innerVariant = validation?.isError ? "error" : variant;
	const {isDarkMode} = useThemeMode();

	const [search, setSearch] = useState("");

	const currentOption = useMemo(
		() =>  options.find(option => option.id === value),
		[options, value]
	);

	const filteredOptions = useMemo(() => {
		if (!search.length || !onSearch) {
			return options;
		}

		return options.filter(option => onSearch(search, option));
	}, [options, search]);

	return (
		<InputElements
			className={className}
			filledIcon
			hintText={hintText}
			label={label}
			rightIcon={<ChevronDownIcon />}
			validation={validation}
			variant={innerVariant}
		>
			<Dropdown
				space={8}
				position="bottom-start"
				sameWidth
				triggerClassName={selectCx("trigger", innerVariant, {isDarkMode})}
				popupClassName={styles.popup}
				popupContentClassName={styles.popupContent}
				onClose={() => setSearch("")}
				trigger={
					<>
						{leftIcon && cloneElement(leftIcon, {className: cx("icon", "leftIcon", size, innerVariant)})}
						<input
							className={cx(
								"input",
								styles.input,
								size,
								innerVariant,
								leftIcon && "withLeftIcon",
								"withRightIcon",
							)}
							value={currentOption?.name ?? ""}
							readOnly
							tabIndex={-1}
							{...props}
						/>
					</>
				}
				items={
					[
						onSearch && (
							<SearchInput
								key="search"
								onChange={setSearch}
								value={search}
								placeholder="Search"
							/>
						),
						...filteredOptions.map((option) => ({
							label: option.name,
							onClick: () => onChange(option.id),
							tooltip: option.tooltip,
							icon: option.icon,
							rightIcon: option.id === value ? <CheckmarkIcon /> : undefined,
						}))
					]
				}
			/>
		</InputElements>
	)
}
