import classNames from "classnames/bind";
import React, {cloneElement, ReactElement, ReactNode} from "react";

import {ButtonIcon, Dropdown} from "@/shared/v2";
import {ChevronDownIcon, ChevronRightIcon} from "@/icons";
import {ChatLayoutMode, useChatLayoutModeContext} from "@/context/chat-contexts";

import styles from "./option-dropdown.module.scss";

const cx = classNames.bind(styles);

interface Option<T> {
  label: string;
  value: T;
  description?: string;
  icon: ReactElement;
  buttonClassName?: string;
}

export interface OptionDropdownProps<T> {
  activeClass: string;
  className?: string;
  isActive: boolean;
  options: Option<T>[];
  selected: string | null;
  onClick: () => void;
  onSelect: (T) => void;
  disabled?: boolean;
}

export function OptionDropdown<T>(props: OptionDropdownProps<T>): ReactElement {
  const {
    isActive,
    activeClass,
    options,
    selected,
    className,
    onSelect,
    onClick,
    disabled = false,
  } = props;

  const {mode} = useChatLayoutModeContext();
  const chevronDirection = mode === ChatLayoutMode.HOME ? "bottom" : "right";

  const activeOption = options.find(option => option.value === selected);

  const getCurrentIconWithActiveClass = (): ReactElement | null => {
    if (!activeOption) {
      return null;
    }
    const icon = activeOption.icon;

    return cloneElement(icon, {className: cx({[activeClass]: isActive}, icon.props.className)});
  }

  return (
    <div className={styles.optionDropdownWrapper}>
      <ButtonIcon
        className={cx("button", activeOption?.buttonClassName || className, {[activeClass]: isActive})}
        icon={getCurrentIconWithActiveClass()}
        onClick={onClick}
        disabled={disabled}
      />
      <Dropdown
        items={options.map(option => ({
          label: option.label,
          onClick: () => onSelect(option.value),
          active: option.value === selected,
          tooltip: option.description,
          icon: option.icon,
        }))}
        space={chevronDirection === "bottom" ? 14 : 4}
        position={chevronDirection}
        disabled={disabled}
        trigger={(open) => (
          <ButtonIcon
            className={styles.chevron}
            icon={chevronDirection === "bottom" ?
              <ChevronDownIcon /> :
              <ChevronRightIcon />}
            isActive={open}
            disabled={disabled}
          />
        )} />
    </div>
  );
}
