import classNames from "classnames/bind";
import React, {
	FormEvent,
	forwardRef,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";

import {
	useChatConversationContext,
	useChatFollowUpsContext,
	useChatPersonaContext,
	useChatSendQuestionContext,
	useChatImageContext, useChatToolsContext,
	ChatTool
} from "../../context/chat-contexts";
import {KeyboardEventMiddlewareContextProvider} from "../../context/keyboard-event-middleware-context";
import {useThemeMode} from "../../context/theme-mode-context";
import {useWorkspaceContext} from "../../context/workspace-context";
import {PaperclipIcon} from "../../icons";
import {CHAT_COMMANDS} from "../../shared/constants/constants";
import {ButtonIcon} from "../../shared/v2";
import {ActionsDropdown} from "./actions-dropdown";
import CommandTextArea from "./command-text-area";
import {PersonaTabWrapper} from "./persona-tab-wrapper";
import {SourcesSection} from "./sources-section";
import {SubmitButton} from "./submit-button";
import {UploadedImageSection} from "./uploaded-image-section";
import {Toolbar} from "./toolbar";

import styles from "./chat-bubble.module.scss";

const cx = classNames.bind(styles);

export type ChatBubbleContext = "home" | "chat";

export interface ChatInputBoxProps {
	className?: string;
	promptValue?: string;
	clearPrompt: () => void;
}

export const ChatBubble = forwardRef<HTMLDivElement, ChatInputBoxProps>(({promptValue, clearPrompt}, ref): ReactElement => {
	const {tool} = useChatToolsContext();
	const {conversation} = useChatConversationContext();
	const {sendQuestion, isSendingQuestion} = useChatSendQuestionContext();
	const {followUps} = useChatFollowUpsContext();
	const {workspace} = useWorkspaceContext();
	const {isDarkMode} = useThemeMode();
	const {image} = useChatImageContext();

	const [value, setValue] = useState("");
	const isDisabled = !workspace.id || (!value.length && !image) || isSendingQuestion;
	const {personas} = useChatPersonaContext();

	useEffect(() => {
		if (promptValue) {
			setValue(promptValue);
		}
	}, [promptValue]);

	const clearInput = () => {
		setValue("");
		clearPrompt();
	};

	const handleResponse = useCallback(async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		if (isDisabled) {
			return;
		}

		clearInput();
		await sendQuestion(value);
	}, [isDisabled, value, sendQuestion, conversation, setValue]);

	const showSourcesAndCampaigns = useMemo(() => {
		return tool === ChatTool.AGENT || !tool;
	}, [tool]);

	return (
		<div className={styles.chatBubbleWrapper} ref={ref}>
			<PersonaTabWrapper>
				<div className={cx("chatBubble", {isDarkMode})}>
					<div className={styles.bottomLine}>
						<div className={styles.chatContentWrapper}>
							<div className={styles.chatAndSourcesWrapper}>
								{showSourcesAndCampaigns && <UploadedImageSection />}

								<KeyboardEventMiddlewareContextProvider>
									<CommandTextArea
										followUps={followUps}
										value={value}
										onChange={setValue}
										placeholder={'Ask anything...'}
										commands={CHAT_COMMANDS}
										personas={personas}
										handleSubmit={handleResponse}
									/>
								</KeyboardEventMiddlewareContextProvider>
							</div>

							{showSourcesAndCampaigns && <SourcesSection />}
						</div>

						<ActionsDropdown disabled={!showSourcesAndCampaigns}>
							<ButtonIcon
								disabled={!showSourcesAndCampaigns}
								icon={<PaperclipIcon />}
								className={styles.icon}
								aria-label='plus-button'
							/>
						</ActionsDropdown>

						<SubmitButton onSubmit={handleResponse} />
					</div>
				</div>
			</PersonaTabWrapper>

			<Toolbar />
		</div>
	);
});
