import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ButtonIcon} from "../../../shared/v2";
import {SendArrowChatIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./submit-button.module.scss";

const cx = classNames.bind(styles);

export interface SubmitButtonProps {
  onSubmit: () => void;
}

export const SubmitButton = ({onSubmit}: SubmitButtonProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<motion.div
			className={cx("submitButton", {isDarkMode})}
		>
			<motion.div
				className={styles.chatSubmit}
			>
				<ButtonIcon
					className={styles.icon}
					icon={<SendArrowChatIcon />}
					onClick={onSubmit}
				/>
			</motion.div>
		</motion.div>
	);
}
