import React, {ReactElement} from "react";

import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatConversationModeContextProvider,
	ChatImageContextProvider,
	ChatPersonaContextProvider,
	ChatSourcesContextProvider
} from "../../../../../context/chat-contexts";

export interface DrawerContextsProps {
	children: ReactElement;
}

export const DrawerContexts = ({children}: DrawerContextsProps): ReactElement => {
	return (
		<ChatConversationContextProvider>
			<ChatSourcesContextProvider>
				<ChatCampaignContextProvider>
					<ChatConversationModeContextProvider>
						<ChatPersonaContextProvider>
							<ChatImageContextProvider>
								{children}
							</ChatImageContextProvider>
						</ChatPersonaContextProvider>
					</ChatConversationModeContextProvider>
				</ChatCampaignContextProvider>
			</ChatSourcesContextProvider>
		</ChatConversationContextProvider>
	);
}
