import React, {ReactElement} from "react";
import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {Body, ButtonIcon, Tooltip, Modal, Button} from "../../shared/v2";
import {BinDeleteIcon, CopyDocumentsIcon} from "../../icons";
import {useToastContext} from "../../context/toast-context";
import {useChatConversationContext} from "../../context/chat-contexts";
import {ChatConversationMessage} from "../../models/ai-model";
import {CHAT_DELETE_MESSAGE_PAIR} from "../../graphql/mutations/ai-mutations";
import {GET_CHAT_CONVERSATION} from "../../graphql/queries/ai-models-queries";

import styles from "./chat-actions.module.scss";

const bStyles = classNames.bind(styles);

interface ButtonTooltipProps {
	icon: ReactElement;
	text: string;
	onClick?: (event) => void;
	iconClassName?: string;
	disabled?: boolean;
}

const ButtonTooltip = ({icon, text, onClick, iconClassName, disabled}: ButtonTooltipProps) => {
	return (
		<Tooltip
			content={
				<Body size="xs" color="text-tertiary">
					{text}
				</Body>
			}
			placement={"top"}
		>
			<ButtonIcon
				size="small"
				color="gray-modern"
				className={iconClassName}
				icon={icon}
				onClick={onClick}
				disabled={disabled}
			/>
		</Tooltip>
	);
};

export interface UserMessageActionsProps {
	className?: string;
	message: ChatConversationMessage;
}

export const UserMessageActions = ({
	className,
	message,
}: UserMessageActionsProps): ReactElement => {
	const {updateToast} = useToastContext();
	const {conversation, refetch} = useChatConversationContext();
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
	const [deleteMessagePair, {loading}] = useMutation(CHAT_DELETE_MESSAGE_PAIR, {
		update(cache, { data: { chatDeleteMessagePair } }) {
			const deletedIds = chatDeleteMessagePair.map(msg => msg.id);
			cache.modify({
				id: cache.identify({ __typename: 'ChatConversation', id: conversation?.id }),
				fields: {
					messages(existingMessages = [], { readField }) {
						return existingMessages.filter(
							messageRef => !deletedIds.includes(readField('id', messageRef))
						);
					}
				}
			});
		},
		onCompleted: () => {
			updateToast({
				description: "Message deleted",
				type: "success",
			});
		}
	});

	const handleDeleteClick = (event) => {
		event.stopPropagation();
		setShowDeleteConfirm(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await deleteMessagePair({
				variables: {
					messageId: message.id,
				},
				optimisticResponse: {
					chatDeleteMessagePair: [
						{
							id: message.id,
							content: message.content,
							role: message.role,
							deletedAt: new Date().toISOString(),
							__typename: "ChatConversationMessage"
						},
						// Include the next message (assistant response) if it exists
						message.position < (conversation?.messages?.length || 0) - 1 ? {
							id: conversation?.messages?.[message.position + 1]?.id,
							content: conversation?.messages?.[message.position + 1]?.content,
							role: conversation?.messages?.[message.position + 1]?.role,
							deletedAt: new Date().toISOString(),
							__typename: "ChatConversationMessage"
						} : null
					].filter(Boolean)
				}
			});
			setShowDeleteConfirm(false);
		} catch (error) {
			updateToast({
				description: error instanceof Error ? error.message : "Failed to delete message",
				type: "failure",
			});
		}
	};

	const handleCancelDelete = () => {
		setShowDeleteConfirm(false);
	};

	const handleCopy = async (event) => {
		event.stopPropagation();
		try {
			await navigator.clipboard.writeText(message.content);
			updateToast({
				description: "Message copied to clipboard",
				type: "success",
			});
		} catch (error) {
			updateToast({
				description: "Failed to copy message",
				type: "failure",
			});
		}
	};

	return (
		<>
			<div className={bStyles("actions", className)}>
				<ButtonTooltip
					icon={<CopyDocumentsIcon />}
					text="Copy message"
					onClick={handleCopy}
				/>
				<ButtonTooltip
					icon={<BinDeleteIcon />}
					text="Delete message"
					onClick={handleDeleteClick}
					disabled={loading}
				/>
			</div>

			<Modal
				isOpen={showDeleteConfirm}
				onClose={handleCancelDelete}
				title="Delete Message"
				portal
			>
				<Body>
					This will delete both your message and the assistant's response. Are you sure you want to continue?
				</Body>
				<div className={bStyles("modal-actions")}>
					<Button
						variant="text"
						onClick={handleCancelDelete}
					>
						Cancel
					</Button>
					<Button
						variant="filled"
						onClick={handleConfirmDelete}
						disabled={loading}
					>
						Delete
					</Button>
				</div>
			</Modal>
		</>
	);
};
