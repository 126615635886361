import React, {Dispatch, MutableRefObject, ReactElement, ReactNode, SetStateAction, createContext, useRef, useState} from "react";

export enum ChatTaskType {
  IS_SENDING_QUESTION = "IS_SENDING_QUESTION",
}

export interface ChatStateWithRef<T> {
  state: T;
  stateRef: MutableRefObject<T>;
  setState: Dispatch<SetStateAction<T>>;
}

export interface ChatStateContextValue {
  [ChatTaskType.IS_SENDING_QUESTION]: ChatStateWithRef<boolean>;
}

export const ChatStateContext =
  createContext<ChatStateContextValue | undefined>(undefined);

export const ChatStateContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const [isSendingQuestion, setIsSendingQuestion] = useState(false);
  const isSendingQuestionRef = useRef(isSendingQuestion);

  return (
    <ChatStateContext.Provider value={{
      [ChatTaskType.IS_SENDING_QUESTION]: {
        state: isSendingQuestion,
        stateRef: isSendingQuestionRef,
        setState: setIsSendingQuestion,
      },
    }}>
      {children}
    </ChatStateContext.Provider>
  );
};

export const useChatStateContext = (): ChatStateContextValue => {
  const context = React.useContext(ChatStateContext);

  if (context === undefined) {
    throw new Error(
      "useChatStateContext must be used within a ChatStateContextProvider",
    );
  }

  return context;
};
