import {NetworkStatus} from "@apollo/client";
import {useSearchParams} from "react-router-dom";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {CampaignCard} from "./campaign-card";
import {CreateCampaignCard} from "./create-campaign-card";
import {GET_ALL_SURVEYS} from "../../../graphql/queries/survey-queries";
import {LoadingContainer, DebounceSearch} from "../../../shared/v2";
import {SurveyPageData} from "../../../models/survey";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {SearchIcon} from "@/icons";
import {TablePagination} from "@/shared/components/table/table-pagination";
import {useLoadingQuery} from "@/hooks";

import styles from "./survey-dashboard.module.scss";

const PAGE_SIZE = 20;

const SurveyDashboard = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [searchValue, setSearchValue] = useState<string>("");

	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const [currentPage, setCurrentPage] = useState(0);

	const {
		data,
		networkStatus,
		handleFetchMore,
		fragment,
	} = useLoadingQuery<SurveyPageData>(GET_ALL_SURVEYS, {
		skip: !workspaceId,
		errorPolicy: "all",
		variables: {
			workspaceId,
			limit: pageSize,
			filter: {
				name: searchValue
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
	});

	const handleChangeSearch = (value: string): void => {
		setSearchValue(value);
		setCurrentPage(0);
	}

	const totalCount = useMemo(() => {
		if (!data) return 0;
		return data?.workspaceSurveys?.items?.length + data?.workspaceSurveys?.remaining;
	}, [data?.workspaceSurveys?.items, data?.workspaceSurveys?.remaining]);

	const startingRow = currentPage === 0 ? currentPage + 1 : (currentPage * pageSize) + 1;
	const endRow = ((currentPage + 1) * pageSize) > totalCount
		? totalCount : (currentPage + 1) * pageSize;

	const pageCount = useMemo(() => {
		return Math.ceil(totalCount / pageSize);
	}, [totalCount, pageSize])

	const dataLength = useMemo(() => {
		if (!data) return 0;
		return data?.workspaceSurveys?.items?.length;
	}, [data?.workspaceSurveys?.items])

	const handlePageSizeChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	const handleNext = (): void => {
		if ((currentPage + 2) * pageSize > dataLength) handleFetchMore(pageSize);
		setCurrentPage(prev => prev + 1);
	};

	const handlePrev = (): void => {
		setCurrentPage(prev => prev - 1);
	};

	const gotoPage = (newPage: number): void => {
		if (currentPage < newPage && (newPage + 1) * pageSize > dataLength) {
			handleFetchMore((newPage - currentPage) * pageSize);
		}
		setCurrentPage(newPage);
	};

	const currentData = useMemo(() => {
		const current = data?.workspaceSurveys.items.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
		return current
	}, [data?.workspaceSurveys.items, currentPage]);

	const renderContent = () => {
		if (networkStatus !== NetworkStatus.ready && !data?.workspaceSurveys.items.length) {
			return <LoadingContainer />;
		}

		return <>
			{networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables ?
				<LoadingContainer /> :
				<div className={styles.list}>
					<CreateCampaignCard />
					{(!data && fragment) ||
						(data && currentData && currentData.map((campaign) => (
						<CampaignCard key={campaign.id} campaign={campaign} />
					)))}
				</div>
		}

			<TablePagination
				currentPage={currentPage}
				startingRow={startingRow}
				endRow={endRow}
				totalCount={totalCount}
				onPageSizeChange={handlePageSizeChange}
				handleNext={handleNext}
				handlePrev={handlePrev}
				gotoPage={gotoPage}
				pageCount={pageCount}
				pageSize={pageSize}
				disablePagination={networkStatus === NetworkStatus.fetchMore}
			/>
		</>
	}

	return (
		<div className={styles.fullWidth}>
			<DebounceSearch
				leftIcon={<SearchIcon />}
				size="small"
				placeholder="Search"
				value={searchValue}
				onChange={handleChangeSearch}
				className={styles.searchInput}
			/>
			{renderContent()}
		</div>
	);
};

export {SurveyDashboard};
