import React, {ReactElement, useContext, useState} from "react";
import {
	COPY_SURVEY,
	DELETE_SURVEY,
	UNPUBLISH_SURVEY,
	UPDATE_SURVEY,
} from "../../../graphql/mutations/survey-mutations";
import {Options, StatusBadge} from "../../../shared";
import {SurveyContext} from "../../../context/survey-context";
import {SurveyNavLink} from "../survey-nav-link";
import {SurveyStatus} from "../../../models/survey";
import {ToastContext} from "../../../context/toast-context";
import {cleanupCache} from "../../../shared/utility/cache-cleanup";
import {useMutation} from "@apollo/client";
import {useNavigate} from "../../../route";
import styles from "./survey-top-nav.module.scss";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {EditableSpan} from "../../../shared/components/editable-span";
import {DetailsPanel} from "../details-panel";
import config from "../../../config";
import {Button} from "../../../shared/v2";
import {ChevronLeftIcon, SparkAiStarsIcon} from "../../../icons";

const SurveyTopNav = (): ReactElement => {
	const navigate = useNavigate();
	const {
		survey: {id: surveyId, status, name: surveyName, questionCount},
		setShowingPublish,
	} = useContext(SurveyContext);
	const {updateToast} = useContext(ToastContext);

	const [name, setName] = useState(surveyName);
	const [showDelete, setShowDelete] = useState(false);
	const [detailsPanel, setDetailsPanel] = useState(false);

	const [updateName] = useMutation(UPDATE_SURVEY);
	const [unpublishSurvey] = useMutation(UNPUBLISH_SURVEY, {
		onCompleted: () => updateToast({description: "Vurvey closed", type: "informational"}),
	});
	const [copySurvey] = useMutation(COPY_SURVEY, {
		onCompleted: data => {
			if (!data) return;
			updateToast({
				type: "informational",
				description: "Copied survey",
			});
			navigate(`/survey/${data.duplicateSurvey.id}/questions`, {workspace: true});
		},
	});

	const [deleteSurvey] = useMutation(DELETE_SURVEY, {
		onCompleted: () => {
			navigate("/", {workspace: true});
			updateToast({description: "Campaign deleted", type: "informational"});
		},
		update(cache, {data}) {
			if (!data) return;
			const survey = cache.identify(data.deleteSurvey);
			cache.evict({id: survey});
		},
	});

	const handleUpdate = (newValue: string): void => {
		if (!newValue || newValue.replace(/\s/g, "").length === 0) {
			setName(surveyName);
			return;
		}
		updateName({
			variables: {id: surveyId, changes: {name: newValue}},
		});
	};

	const handlePreview = (): void => {
		const open = window.open(`${config.responder}/#/${surveyId}/preview`);
		(open as Window).opener = null;
	};

	const handleCopy = (): void => {
		copySurvey({variables: {id: surveyId}});
	};

	const handleDelete = (): void => {
		deleteSurvey({
			variables: {id: surveyId},
		});
	};

	const handlePublish = (): void => setShowingPublish(true);

	const handleUnpublish = (): void => {
		unpublishSurvey({variables: {id: surveyId}});
	};

	const handleBack = (): void => {
		cleanupCache(["question",
			"questions",
			"answers",
			"answer",
			"contacts",
			"surveyMembers",
			"videoTranscript"]);


			navigate("/campaigns");
	};

	const toggleDeleteModal = (): void => setShowDelete(prev => !prev);
	const toggleDetailsPanel = (): void => setDetailsPanel(prev => !prev);

	const openInCopilot = () => {
		navigate(
			{
				pathname: "/workflow/conversation",
				search: {surveyIds: [surveyId]},
			},
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<Button
					variant="outlined"
					leftIcon={<ChevronLeftIcon/>}
					onClick={handleBack}
					filledIcon
					className={styles.backButton}
				>
          Back
				</Button>
			</div>
			<div className={styles.center}>
				<div className={styles.titleWrapper}>
					<EditableSpan value={name} handleInput={handleUpdate} className={styles.nameSpan}/>
					<StatusBadge status={status}/>
				</div>

				<nav className={styles.navigation}>
					<SurveyNavLink
						linkTo="questions"
						text="Build"
						icon="stack"
					/>
					<SurveyNavLink
						linkTo="settings"
						text="Review"
						icon="bullseye"
					/>
					<SurveyNavLink
						linkTo="participants"
						text="Launch"
						disabled={status === SurveyStatus.DRAFT}
						icon="rocket-launch"
					/>
					<SurveyNavLink
						linkTo="results"
						text="Results"
						disabled={status === SurveyStatus.DRAFT}
						icon="wand"
						alsoActive={["participation", "results"]}
					/>
					<SurveyNavLink
						linkTo="analyze"
						text="Analyze"
						disabled={status === SurveyStatus.DRAFT}
						icon="table"
					/>
					<SurveyNavLink
						linkTo="summary"
						text="Summary"
						disabled={status === SurveyStatus.DRAFT}
						icon="note"
					/>
				</nav>
			</div>
			<div className={styles.right}>
				<Button
					leftIcon={<SparkAiStarsIcon />}
					style="ai"
					onClick={openInCopilot}
					disabled={status === SurveyStatus.DRAFT}
				>
          Start conversation
				</Button>
				<Options
					type="menu-vertical"
					options={[
						{
							name: "Copy",
							icon: "copy",
							actionOptions: {onClick: handleCopy},
						},
						{
							name: "Preview",
							icon: "eye",
							iconFill: "black",
							actionOptions: {
								onClick: handlePreview,
								skip: questionCount === 0,
							},
						},
						{
							name: "Publish",
							icon: "paper-plane",
							iconFill: "black",
							actionOptions: {
								onClick: handlePublish,
								skip: questionCount === 0 || status === SurveyStatus.OPEN,
							},
						},
						{
							name: "Close",
							icon: "lock",
							actionOptions: {
								onClick: handleUnpublish,
								skip: status !== SurveyStatus.OPEN,
							},
						},
						{
							name: "Details",
							icon: "book",
							actionOptions: {onClick: toggleDetailsPanel},
						},
						{
							name: "Delete",
							icon: "trash",
							actionOptions: {onClick: toggleDeleteModal},
						},
					]}
				/>
			</div>

			<DeleteConfirmModal
				warningText="Are you sure you wish to delete this campaign?
				Everything will be deleted, and this cannot be undone"
				onClose={toggleDeleteModal}
				handleConfirm={handleDelete}
				isOpen={showDelete}
			/>
			<DetailsPanel isShowing={detailsPanel} toggleShowing={toggleDetailsPanel}/>
		</div>
	);
};

export {SurveyTopNav};
